import React, { Fragment } from "react";

const Landing = () => {
  return (
    <Fragment>
      <section className="pt-4 pb-0 card-grid">
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-6">
              <div
                className="card card-overlay-bottom card-grid-lg card-bg-scale"
                style={{
                  backgroundImage: "url(/imgs/blog/1by1/01.jpg)",
                  backgroundPosition: "center left",
                  backgroundSize: "cover",
                }}
              >
                <span className="card-featured" title="">
                  <i className="fas fa-star"></i>
                </span>

                <div className="card-img-overlay d-flex align-items-center p-3 p-sm-4">
                  <div className="w-100 mt-auto">
                    <a href="#" className="badge text-bg-danger mb-2">
                      <i className="fas fa-circle me-2 small fw-bold"></i>مگامنو
                    </a>

                    <h2 className="text-white h1">
                      <a
                        href="post-single-4.html"
                        className="btn-link stretched-link text-reset"
                      >
                        ده نشانه که نشان می دهد برای راه اندازی یک استارتاپ جدید
                        به آن نیاز دارید.
                      </a>
                    </h2>
                    <p className="text-white">
                      در این صورت می توان امید داشت که تمام و دشواری موجود در
                      ارائه راهکارها و شرایط سخت تایپ به پایان رسد.{" "}
                    </p>

                    <ul className="nav nav-divider text-white-force align-items-center d-none d-sm-inline-block">
                      <li className="nav-item">
                        <div className="nav-link">
                          <div className="d-flex align-items-center text-white position-relative">
                            <div className="avatar avatar-sm">
                              <img
                                className="avatar-img rounded-circle"
                                src="/imgs/avatar.jpg"
                                alt="avatar"
                              />
                            </div>
                            <span className="ms-3">
                              با{" "}
                              <a
                                href="#"
                                className="stretched-link text-reset btn-link"
                              >
                                سهیلا صالحی
                              </a>
                            </span>
                          </div>
                        </div>
                      </li>
                      <li className="nav-item">15 دی، 1400</li>
                      <li className="nav-item">5 دقیقه زمان مطالعه</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row g-4">
                <div className="col-12">
                  <div
                    className="card card-overlay-bottom card-grid-sm card-bg-scale"
                    style={{
                      backgroundImage: "url(/imgs/blog/1by1/02.jpg)",
                      backgroundPosition: "center left",
                      backgroundSize: "cover",
                    }}
                  >
                    <div className="card-img-overlay d-flex align-items-center p-3 p-sm-4">
                      <div className="w-100 mt-auto">
                        <a href="#" className="badge text-bg-warning mb-2">
                          <i className="fas fa-circle me-2 small fw-bold"></i>
                          تکنولوژی
                        </a>

                        <h4 className="text-white">
                          <a
                            href="post-single-4.html"
                            className="btn-link stretched-link text-reset"
                          >
                            بهترین تابلوهای Pinterest برای یادگیری در مورد تجارت
                          </a>
                        </h4>

                        <ul className="nav nav-divider text-white-force align-items-center d-none d-sm-inline-block">
                          <li className="nav-item position-relative">
                            <div className="nav-link">
                              با{" "}
                              <a
                                href="#"
                                className="stretched-link text-reset btn-link"
                              >
                                مهدی راد
                              </a>
                            </div>
                          </li>
                          <li className="nav-item">18 تیر، 1400</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div
                    className="card card-overlay-bottom card-grid-sm card-bg-scale"
                    style={{
                      backgroundImage: "url(/imgs/blog/1by1/03.jpg)",
                      backgroundPosition: "center left",
                      backgroundSize: "cover",
                    }}
                  >
                    <div className="card-img-overlay d-flex align-items-center p-3 p-sm-4">
                      <div className="w-100 mt-auto">
                        <a href="#" className="badge text-bg-success mb-2">
                          <i className="fas fa-circle me-2 small fw-bold"></i>
                          اقتصاد
                        </a>

                        <h4 className="text-white">
                          <a
                            href="post-single-4.html"
                            className="btn-link stretched-link text-reset"
                          >
                            دلیل کاهش نرخ دلار{" "}
                          </a>
                        </h4>

                        <ul className="nav nav-divider text-white-force align-items-center d-none d-sm-inline-block">
                          <li className="nav-item position-relative">
                            <div className="nav-link">
                              با{" "}
                              <a
                                href="#"
                                className="stretched-link text-reset btn-link"
                              >
                                مسعود خالدی
                              </a>
                            </div>
                          </li>
                          <li className="nav-item">8 دی، 1400</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div
                    className="card card-overlay-bottom card-grid-sm card-bg-scale"
                    style={{
                      backgroundImage: "url(/imgs/blog/1by1/04.jpg)",
                      backgroundPosition: "center left",
                      backgroundSize: "cover",
                    }}
                  >
                    <div className="card-img-overlay d-flex align-items-center p-3 p-sm-4">
                      <div className="w-100 mt-auto">
                        <a href="#" className="badge text-bg-info mb-2">
                          <i className="fas fa-circle me-2 small fw-bold"></i>
                          ورزش
                        </a>

                        <h4 className="text-white">
                          <a
                            href="post-single-4.html"
                            className="btn-link stretched-link text-reset"
                          >
                            جدول لیگ در پایان هفته
                          </a>
                        </h4>

                        <ul className="nav nav-divider text-white-force align-items-center d-none d-sm-inline-block">
                          <li className="nav-item position-relative">
                            <div className="nav-link">
                              با{" "}
                              <a
                                href="#"
                                className="stretched-link text-reset btn-link"
                              >
                                شادی اسدی
                              </a>
                            </div>
                          </li>
                          <li className="nav-item">28 آذر، 1400</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="position-relative">
        <div className="container" data-sticky-container>
          <div className="row">
            <div className="col-lg-9">
              <div className="mb-4">
                <h2 className="m-0">
                  <i className="bi bi-hourglass-top me-2"></i>سایر اخبار مهم
                </h2>
                <p>آخرین اخبار، تصاویر، فیلم ها و گزارش های ویژه</p>
              </div>
              <div className="row gy-4">
                <div className="col-sm-6">
                  <div className="card">
                    <div className="position-relative">
                      <img
                        className="card-img"
                        src="/imgs/blog/4by3/01.jpg"
                        alt="Card image"
                      />
                      <div className="card-img-overlay d-flex align-items-start flex-column p-3">
                        <div className="w-100 mt-auto">
                          <a href="#" className="badge text-bg-warning mb-2">
                            <i className="fas fa-circle me-2 small fw-bold"></i>
                            تکنولوژی
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card-body px-0 pt-3">
                      <a
                        href="#!"
                        className="mb-0 text-body small"
                        tabIndex={0}
                        role="button"
                        data-bs-container="body"
                        data-bs-toggle="popover"
                        data-bs-trigger="focus"
                        data-bs-placement="top"
                        data-bs-content="شما این تبلیغ را می بینید زیرا فعالیت شما با مخاطبان مورد نظر سایت ما مطابقت دارد."
                      >
                        <i className="bi bi-info-circle ps-1"></i> ویژه
                      </a>
                      <h4 className="card-title mt-2">
                        <a
                          href="post-single.html"
                          className="btn-link text-reset"
                        >
                          فیلم‌های بالیوودی الگوی ضدانقلاب شده!
                        </a>
                      </h4>
                      <p className="card-text">
                        ارائه راهکارها و شرایط سخت تایپ به پایان رسد وزمان مورد
                        نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی سوالات
                        پیوسته اهل دنیای موجود طراحی اساسا مورد...
                      </p>

                      <ul className="nav nav-divider align-items-center d-none d-sm-inline-block">
                        <li className="nav-item">
                          <div className="nav-link">
                            <div className="d-flex align-items-center position-relative">
                              <div className="avatar avatar-xs">
                                <img
                                  className="avatar-img rounded-circle"
                                  src="/imgs/avatar/01.jpg"
                                  alt="avatar"
                                />
                              </div>
                              <span className="ms-3">
                                با{" "}
                                <a
                                  href="#"
                                  className="stretched-link text-reset btn-link"
                                >
                                  ادمین
                                </a>
                              </span>
                            </div>
                          </div>
                        </li>
                        <li className="nav-item">22 آذر، 1400</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="card">
                    <div className="position-relative">
                      <img
                        className="card-img"
                        src="/imgs/blog/4by3/06.jpg"
                        alt="Card image"
                      />
                      <div className="card-img-overlay d-flex align-items-start flex-column p-3">
                        <div className="w-100 mb-auto d-flex justify-content-end">
                          <div className="text-end ms-auto">
                            <div
                              className="icon-md bg-white bg-opacity-25 bg-blur text-white rounded-circle"
                              title="This post has video"
                            >
                              <i className="fas fa-video"></i>
                            </div>
                          </div>
                        </div>

                        <div className="w-100 mt-auto">
                          <a href="#" className="badge text-bg-danger mb-2">
                            <i className="fas fa-circle me-2 small fw-bold"></i>
                            گردشگری
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card-body px-0 pt-3">
                      <h4 className="card-title">
                        <a
                          href="post-single.html"
                          className="btn-link text-reset"
                        >
                          رازهای کوچک کثیف در مورد صنعت تجارت
                        </a>
                      </h4>
                      <p className="card-text">
                        چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان
                        که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و
                        کاربردهای متنوع با هدف بهبود ابزارهای کاربردی...
                      </p>

                      <ul className="nav nav-divider align-items-center d-none d-sm-inline-block">
                        <li className="nav-item">
                          <div className="nav-link">
                            <div className="d-flex align-items-center position-relative">
                              <div className="avatar avatar-xs">
                                <img
                                  className="avatar-img rounded-circle"
                                  src="/imgs/avatar/02.jpg"
                                  alt="avatar"
                                />
                              </div>
                              <span className="ms-3">
                                با{" "}
                                <a
                                  href="#"
                                  className="stretched-link text-reset btn-link"
                                >
                                  میلاد بابایی
                                </a>
                              </span>
                            </div>
                          </div>
                        </li>
                        <li className="nav-item">7 دی، 1400</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="card">
                    <div className="position-relative">
                      <img
                        className="card-img"
                        src="/imgs/blog/4by3/03.jpg"
                        alt="Card image"
                      />
                      <div className="card-img-overlay d-flex align-items-start flex-column p-3">
                        <div className="w-100 mt-auto">
                          <a href="#" className="badge text-bg-success mb-2">
                            <i className="fas fa-circle me-2 small fw-bold"></i>
                            اجتماعی
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card-body px-0 pt-3">
                      <h4 className="card-title">
                        <a
                          href="post-single.html"
                          className="btn-link text-reset"
                        >
                          عادات بدی که افراد در صنعت باید آنها را ترک کنند!!!
                        </a>
                      </h4>
                      <p className="card-text">
                        دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به پایان
                        رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و
                        جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا...
                      </p>

                      <ul className="nav nav-divider align-items-center d-none d-sm-inline-block">
                        <li className="nav-item">
                          <div className="nav-link">
                            <div className="d-flex align-items-center position-relative">
                              <div className="avatar avatar-xs">
                                <img
                                  className="avatar-img rounded-circle"
                                  src="/imgs/avatar.jpg"
                                  alt="avatar"
                                />
                              </div>
                              <span className="ms-3">
                                با{" "}
                                <a
                                  href="#"
                                  className="stretched-link text-reset btn-link"
                                >
                                  علی علیزاده
                                </a>
                              </span>
                            </div>
                          </div>
                        </li>
                        <li className="nav-item">17 تیر، 1400</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="card">
                    <div className="position-relative">
                      <img
                        className="card-img"
                        src="/imgs/blog/4by3/04.jpg"
                        alt="Card image"
                      />
                      <div className="card-img-overlay d-flex align-items-start flex-column p-3">
                        <div className="w-100 mt-auto">
                          <a href="#" className="badge text-bg-primary mb-2">
                            <i className="fas fa-circle me-2 small fw-bold"></i>
                            ورزش
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card-body px-0 pt-3">
                      <h4 className="card-title">
                        <a
                          href="post-single.html"
                          className="btn-link text-reset"
                        >
                          سال 2022 رویایی و فوق العاده برای طارمی
                        </a>
                      </h4>
                      <p className="card-text">
                        برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با
                        هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت
                        و سه درصد گذشته، حال و آینده شناخت...
                      </p>

                      <ul className="nav nav-divider align-items-center d-none d-sm-inline-block">
                        <li className="nav-item">
                          <div className="nav-link">
                            <div className="d-flex align-items-center position-relative">
                              <div className="avatar avatar-xs">
                                <div className="avatar-img rounded-circle bg-success">
                                  <span className="text-white position-absolute top-50 start-50 translate-middle fw-bold small">
                                    SL
                                  </span>
                                </div>
                              </div>
                              <span className="ms-3">
                                با{" "}
                                <a
                                  href="#"
                                  className="stretched-link text-reset btn-link"
                                >
                                  سهراب اسدی
                                </a>
                              </span>
                            </div>
                          </div>
                        </li>
                        <li className="nav-item">29 مرداد، 1400</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="card">
                    <div className="position-relative">
                      <div className="card-image position-relative">
                        <img
                          className="card-img"
                          src="/imgs/blog/4by3/05.jpg"
                          alt="Card image"
                        />
                        <div className="card-img-overlay d-flex align-items-start flex-column p-3">
                          <div className="w-100 my-auto">
                            <div className="player-wrapper bg-light rounded">
                              <audio className="player-audio">
                                <source
                                  src="assets/images/videos/audio.mp3"
                                  type="audio/mp3"
                                />
                              </audio>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body px-0 pt-3">
                      <h4 className="card-title">
                        <a
                          href="post-single.html"
                          className="btn-link text-reset"
                        >
                          طرح مجلس برای گرفتن مالیات از سفته بازها
                        </a>
                      </h4>
                      <p className="card-text">
                        ارائه راهکارها و شرایط سخت تایپ به پایان رسد وزمان مورد
                        نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی سوالات
                        پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده...{" "}
                      </p>

                      <ul className="nav nav-divider align-items-center d-none d-sm-inline-block">
                        <li className="nav-item">
                          <div className="nav-link">
                            <div className="d-flex align-items-center position-relative">
                              <div className="avatar avatar-xs">
                                <img
                                  className="avatar-img rounded-circle"
                                  src="/imgs/avatar.jpg"
                                  alt="avatar"
                                />
                              </div>
                              <span className="ms-3">
                                با{" "}
                                <a
                                  href="#"
                                  className="stretched-link text-reset btn-link"
                                >
                                  نازنین لولایی
                                </a>
                              </span>
                            </div>
                          </div>
                        </li>
                        <li className="nav-item">11 دی، 1400</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="card">
                    <div className="position-relative">
                      <img
                        className="card-img"
                        src="/imgs/blog/4by3/12.jpg"
                        alt="Card image"
                      />
                      <div className="card-img-overlay d-flex align-items-start flex-column p-3">
                        <div className="w-100 mt-auto">
                          <a href="#" className="badge text-bg-danger mb-2">
                            <i className="fas fa-circle me-2 small fw-bold"></i>
                            اقتصاد
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card-body px-0 pt-3">
                      <h4 className="card-title">
                        <a
                          href="post-single.html"
                          className="btn-link text-reset"
                        >
                          رونمایی از طرح بزرگترین تلسکوپ نوری آسیا
                        </a>
                      </h4>
                      <p className="card-text">
                        متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را
                        برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ
                        پیشرو در زبان فارسی ایجاد کرد. در این صورت می توان...
                      </p>

                      <ul className="nav nav-divider align-items-center d-none d-sm-inline-block">
                        <li className="nav-item">
                          <div className="nav-link">
                            <div className="d-flex align-items-center position-relative">
                              <div className="avatar avatar-xs">
                                <img
                                  className="avatar-img rounded-circle"
                                  src="/imgs/avatar.jpg"
                                  alt="avatar"
                                />
                              </div>
                              <span className="ms-3">
                                با{" "}
                                <a
                                  href="#"
                                  className="stretched-link text-reset btn-link"
                                >
                                  زهرا عظیمی
                                </a>
                              </span>
                            </div>
                          </div>
                        </li>
                        <li className="nav-item">1 خرداد، 1400</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 mt-5 mt-lg-0">
              <div data-sticky data-margin-top="80" data-sticky-for="767">
                <div className="row g-2">
                  <div className="col-4">
                    <a
                      href="#"
                      className="bg-facebook rounded text-center text-white-force p-3 d-block"
                    >
                      <i className="fab fa-facebook-square fs-5 mb-2"></i>
                      <h6 className="m-0">1.5K</h6>
                      <span className="small">طرفدار</span>
                    </a>
                  </div>
                  <div className="col-4">
                    <a
                      href="#"
                      className="bg-instagram-gradient rounded text-center text-white-force p-3 d-block"
                    >
                      <i className="fab fa-instagram fs-5 mb-2"></i>
                      <h6 className="m-0">1.8M</h6>
                      <span className="small">حامیان</span>
                    </a>
                  </div>
                  <div className="col-4">
                    <a
                      href="#"
                      className="bg-youtube rounded text-center text-white-force p-3 d-block"
                    >
                      <i className="fab fa-youtube-square fs-5 mb-2"></i>
                      <h6 className="m-0">22K</h6>
                      <span className="small">بازدید</span>
                    </a>
                  </div>
                </div>

                <div>
                  <h4 className="mt-4 mb-3">برگزیده ها</h4>

                  <div
                    className="text-center mb-3 card-bg-scale position-relative overflow-hidden rounded bg-dark-overlay-4 "
                    style={{
                      backgroundImage: "url(/imgs/blog/4by3/01.jpg)",
                      backgroundPosition: "center left",
                      backgroundSize: "cover",
                    }}
                  >
                    <div className="p-3">
                      <a
                        href="#"
                        className="stretched-link btn-link text-white h5"
                      >
                        گردشگری
                      </a>
                    </div>
                  </div>

                  <div
                    className="text-center mb-3 card-bg-scale position-relative overflow-hidden rounded"
                    style={{
                      backgroundImage: "url(/imgs/blog/4by3/02.jpg)",
                      backgroundPosition: "center left",
                      backgroundSize: "cover",
                    }}
                  >
                    <div className="bg-dark-overlay-4 p-3">
                      <a
                        href="#"
                        className="stretched-link btn-link text-white h5"
                      >
                        اقتصاد
                      </a>
                    </div>
                  </div>

                  <div
                    className="text-center mb-3 card-bg-scale position-relative overflow-hidden rounded"
                    style={{
                      backgroundImage: "url(/imgs/blog/4by3/03.jpg)",
                      backgroundPosition: "center left",
                      backgroundSize: "cover",
                    }}
                  >
                    <div className="bg-dark-overlay-4 p-3">
                      <a
                        href="#"
                        className="stretched-link btn-link text-white h5"
                      >
                        فرهنگ
                      </a>
                    </div>
                  </div>

                  <div
                    className="text-center mb-3 card-bg-scale position-relative overflow-hidden rounded"
                    style={{
                      backgroundImage: "url(/imgs/blog/4by3/04.jpg)",
                      backgroundPosition: "center left",
                      backgroundSize: "cover",
                    }}
                  >
                    <div className="bg-dark-overlay-4 p-3">
                      <a
                        href="#"
                        className="stretched-link btn-link text-white h5"
                      >
                        تکنولوژی
                      </a>
                    </div>
                  </div>

                  <div
                    className="text-center mb-3 card-bg-scale position-relative overflow-hidden rounded"
                    style={{
                      backgroundImage: "url(/imgs/blog/4by3/05.jpg)",
                      backgroundPosition: "center left",
                      backgroundSize: "cover",
                    }}
                  >
                    <div className="bg-dark-overlay-4 p-3">
                      <a
                        href="#"
                        className="stretched-link btn-link text-white h5"
                      >
                        ورزش
                      </a>
                    </div>
                  </div>

                  <div className="text-center mt-3">
                    <a href="#" className="text-body text-primary-hover">
                      <u>مشاهده همه</u>
                    </a>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-sm-6 col-lg-12">
                    <h4 className="mt-4 mb-3">پربحث ها</h4>

                    <div className="card mb-3">
                      <div className="row g-3">
                        <div className="col-4">
                          <img
                            className="rounded"
                            src="/imgs/blog/4by3/thumb/01.jpg"
                            alt=""
                          />
                        </div>
                        <div className="col-8">
                          <h6>
                            <a
                              href="post-single-2.html"
                              className="btn-link stretched-link text-reset"
                            >
                              خرید و فروش ارز در کانال 37 هزار تومانی
                            </a>
                          </h6>
                          <div className="small mt-1">17 بهمن، 1400</div>
                        </div>
                      </div>
                    </div>

                    <div className="card mb-3">
                      <div className="row g-3">
                        <div className="col-4">
                          <img
                            className="rounded"
                            src="/imgs/blog/4by3/thumb/02.jpg"
                            alt=""
                          />
                        </div>
                        <div className="col-8">
                          <h6>
                            <a
                              href="post-single-2.html"
                              className="btn-link stretched-link text-reset"
                            >
                              کاهش 192 هزار میلیارد تومانی بدهی دولت
                            </a>
                          </h6>
                          <div className="small mt-1">4 مهر، 1400</div>
                        </div>
                      </div>
                    </div>

                    <div className="card mb-3">
                      <div className="row g-3">
                        <div className="col-4">
                          <img
                            className="rounded"
                            src="/imgs/blog/4by3/thumb/03.jpg"
                            alt=""
                          />
                        </div>
                        <div className="col-8">
                          <h6>
                            <a
                              href="post-single-2.html"
                              className="btn-link stretched-link text-reset"
                            >
                              ساخت مسکن موتور محرک کاهش تورم
                            </a>
                          </h6>
                          <div className="small mt-1">30 تیر، 1400</div>
                        </div>
                      </div>
                    </div>

                    <div className="card mb-3">
                      <div className="row g-3">
                        <div className="col-4">
                          <img
                            className="rounded"
                            src="/imgs/blog/4by3/thumb/04.jpg"
                            alt=""
                          />
                        </div>
                        <div className="col-8">
                          <h6>
                            <a
                              href="post-single-2.html"
                              className="btn-link stretched-link text-reset"
                            >
                              آشنایی با کلید موفقیت نهضت ملی مسکن‌
                            </a>
                          </h6>
                          <div className="small mt-1">29 دی 1400</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-6 col-lg-12 my-4">
                    <a href="#" className="d-block card-img-flash">
                      <img src="/imgs/adv.png" alt="" />
                    </a>
                    <div className="smaller text-end mt-2">
                      تبلیغ در سایت{" "}
                      <a href="#" className="text-body">
                        <u>انتخاب</u>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="border-bottom border-primary border-2 opacity-1"></div>
      </div>
    </Fragment>
  );
};

export default Landing;
