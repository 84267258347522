import React, { useEffect, useState } from "react";

import Authenticated from "./Authenticated";
import UnAuthenticated from "./UnAuthenticated";

import Loading from "../Components/Common/Loading";
import Toast from "../Utils/Toast";
import { MainContext, AuthContext, LoadingContext } from "../Context";
import { TransactionSettingGet } from "../Services/Transactions";
import { SettingType } from "../Structures";

const App = () => {
  const [token, setToken] = useState<string | null>(
    localStorage.getItem("Token")
  );
  const [loading, setLoading] = useState(false);
  const [setting, setSetting] = useState<SettingType>({
    cart_sheba: "",
    cart_number: "",
    account_number: "",
    owner_cart: "",
    officePhone: "",
    phone: "",
    workTime: "",
    address: "",
    email: "",
    copyright: "",
    telegramLink: "",
    whatsappLink: "",
    instagramLink: "",
    mapLink: "",
  });

  // ================================================================

  useEffect(() => {
    setLoading(true);
    TransactionSettingGet()
      .then(({ data: res }) => {
        if (res.transaction_status) {
          if (res.data) {
            setSetting(res.data);
          }
        }
      })
      .finally(() => setLoading(false));
  }, []);

  // ================================================================

  return (
    <AuthContext.Provider value={{ token, setToken }}>
      <MainContext.Provider value={{ setting, setSetting }}>
        <LoadingContext.Provider value={{ loading, setLoading }}>
          {token ? <Authenticated /> : <UnAuthenticated />}
          {loading ? <Loading /> : null}
        </LoadingContext.Provider>
      </MainContext.Provider>
    </AuthContext.Provider>
  );
};

export default App;
