import React, { Fragment, type InputHTMLAttributes } from "react";

interface InputProps {
    type: string
    inputClass?: string
    label?: string
    value: any
    setValue: (newValue: any) => void
    placeholder?: string
    disabled?:  boolean
    inputAttrs?: InputHTMLAttributes<any>
}

type InputComponent = (props: InputProps) => JSX.Element;

const Input: InputComponent = (props) => {
  return (
    <Fragment>
      {props.label ? (
        <label className="form-label" htmlFor="exampleInputEmail1">
          {props.label}
        </label>
      ) : null}
      <input
        type={props.type}
        style={{height: "44px"}}
        className={`form-control ${props.inputClass}`}
        value={props.value}
        onChange={(e: any) => props.setValue(e.target.value)}
        placeholder={props.placeholder}
        disabled={props.disabled}
        {...props.inputAttrs}
      />
    </Fragment>
  );
};

export default Input;
