import { createContext, Dispatch, SetStateAction } from "react";

type AuthContextType = {
    token: string | null;
    setToken: Dispatch<SetStateAction<string | null>>;
  };

const AuthContext = createContext<AuthContextType>({
    token: "",
    setToken: () => {},
});

export default AuthContext;
