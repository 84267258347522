import { toast, type Id, type TypeOptions } from "react-toastify";

type ToastParams = (type: TypeOptions, message: string) => Id;

const Toast: ToastParams = (type, message) => {
  return toast(message, {
    type: type,
    position: "top-right",
    theme: "colored",
    closeOnClick: true,
  });
};

export default Toast;
