import Input from "../Components/Base/Input";
import Button from "../Components/Base/Button";
import React, { FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Toast from "../Utils/Toast";
import { TransactinPostEnterPhone } from "../Services/Transactions";

const EnterPhone = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [phone, setPhone] = useState("09120533551");
  const [loading, setLoading] = useState<boolean>(false);

  // ======================================================

  const isValidPhoneNumber = (phone: string) => /^[0-9]{11}$/.test(phone);

  // ======================================================

  const handleSubmitForm = async (e: FormEvent) => {
    e.preventDefault();
    if (isValidPhoneNumber(phone)) {
      setLoading(true);
      TransactinPostEnterPhone({ phoneNumber: phone })
        .then(({ data }) => {
          if (data.transaction_status) {
            Toast("success", data.message);
            navigate("/verify-phone", { state: { phone } });
          } else {
            Toast("error", data.message);
          }
        })
        .finally(() => setLoading(false));
    } else Toast("error", "شماره موبایل وارد شده نامعتبر است!");
  };

  // ======================================================

  useEffect(() => {
    if (!!location.state?.phone) {
      setPhone(location.state.phone);
    }
  }, []);

  // ======================================================
  
  return (
    <div
      className="container my-5 bg-primary-soft rounded-3"
      style={{ background: "rgba(33, 99, 232, 0.1)" }}
    >
      <div className="row">
        <div className="col-md-10 col-lg-8 col-xl-6 mx-auto">
          <div className="p-4 my-5 p-sm-5 rounded">
            <h2 className="text-center">ورود / ثبت نام</h2>

            <form className="mt-4" onSubmit={handleSubmitForm}>
              <div className="mb-4">
                <Input
                  placeholder="شماره موبایل خود را وارد کنید"
                  inputClass="text-center"
                  setValue={setPhone}
                  value={phone}
                  type="tel"
                  label="شماره موبایل"
                  inputAttrs={{ autoFocus: true, dir: 'ltr' }}
                />
              </div>

              <div className="row align-items-center">
                <div className="col-12">
                  <Button
                    btnClass="btn-primary w-100"
                    isLoading={loading}
                    disabled={!isValidPhoneNumber(phone)}
                  >
                    <span>تایید</span>
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterPhone;
