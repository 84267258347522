import React from "react";
import { Link } from "react-router-dom";

const EmptyCart = () => {
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <img
              src="/imgs/cart/empty-cart.svg"
              className="h-200 h-md-300 mb-3"
              alt="سبد خرید خالی است"
            />
            <h3 className="my-2">سبد خرید شما در حال حاضر خالی است!</h3>
            <Link to={{ pathname: "/" }} className="btn btn-primary mt-4">
              بازگشت به خانه
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EmptyCart;
