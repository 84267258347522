import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

interface ProfileLayoutProps {
  children: JSX.Element | JSX.Element[];
}

interface ProfileMenu {
  path: string;
  title: string;
  icon: string;
}

type ProfileLayoutComponent = (props: ProfileLayoutProps) => JSX.Element;

const ProfileLayout: ProfileLayoutComponent = ({ children }) => {
  const location = useLocation();

  // ====================================================

  const profileMenu: ProfileMenu[] = [
    {
      icon: "person",
      path: "/profile",
      title: "اطلاعات کاربری",
    },
    {
      icon: "hr",
      path: "/my-rules",
      title: "حکم‌های من",
    },
    {
      icon: "calendar-event",
      path: "/my-events",
      title: "رویداد‌های ثبت‌نامی",
    },
    {
      icon: "receipt",
      path: "/payment-factor",
      title: "تاریخچه پرداخت‌ها",
    },
    {
      icon: "door-open",
      path: "/coach-register",
      title: "ثبت‌نام به عنوان مربی",
    },
  ];

  // ==============================================================================

  return (
    <div className="container py-4">
      <div className="col-12">
        <div className="card mb-1 position-relative z-index-9">
          <div
            className="py-5 h-200 rounded-3"
            style={{
              backgroundImage: "url(/imgs/blog/16by9/big/07.jpg)",
              backgroundPosition: " center bottom",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <div className="card-body pt-3 pb-0">
            <div className="row d-flex justify-content-between">
              <div className="col-sm-12 col-md-auto text-center text-md-start">
                <div className="avatar avatar-xxl mt-n5">
                  <img
                    className="avatar-img rounded-circle border border-white border-3 shadow"
                    src="/imgs/avatar.jpg"
                    alt="profile image"
                  />
                </div>
              </div>

              <div className="col-sm-12 col-md text-center text-md-start d-md-flex justify-content-between align-items-center">
                <div>
                  <h4 className="my-1">
                    مهدی علیزاده{" "}
                    <i className="bi bi-patch-check-fill text-info small"></i>
                  </h4>
                  <ul className="list-inline">
                    <li className="list-inline-item">حکم زرد</li>
                  </ul>
                  <p className="m-0"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-lg-3">
          <div className="row w-100 mx-auto">
            <div className="list-group p-0 rounded-3">
              {profileMenu.map((item: ProfileMenu) => (
                <Link
                  to={item.path}
                  className={`list-group-item py-3 list-group-item-action ${
                    item.path == location.pathname ? "btn-info-soft" : ""
                  }`}
                >
                  <i className={`bi bi-${item.icon}`}></i>
                  {item.title}
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="col-lg-9 col-12">{children}</div>
      </div>
    </div>
  );
};

export default ProfileLayout;
