import React, { Fragment, useContext, useEffect, useState } from "react";

import Button from "../Components/Base/Button";
import LoadingContext from "../Context/LoadingContext";
import { TransactionRulesGet } from "../Services/Transactions";

import { RuleType } from "../Structures";
import { formatPrice } from "../Utils";
import { PageHeader } from "../Components/Common";

const Rules = () => {
  const { setLoading } = useContext(LoadingContext);

  // ===========================================================================

  const [rules, setRules] = useState<RuleType[]>([]);

  // ===========================================================================

  useEffect(() => {
    setLoading(true);
    TransactionRulesGet()
      .then(({ data: res }) => {
        if (res.transaction_status) {
          setRules(res.data || []);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  // ===========================================================================

  return (
      <Fragment>
        <PageHeader title="احکام" />
        <div className="container">
        <div className="col-12">
          <div className="row g-4">
            {rules.map((rule) => (
              <div className="col-sm-6 col-md-4">
                <div className="card border p-3 h-100">
                  <div className="position-relative">
                    <div className="position-relative z-index-9">
                      <img
                        className="card-img"
                        src={rule.thumbnail}
                        alt={rule.title}
                      />
                    </div>
                  </div>

                  <div className="card-body text-center p-3 px-0">
                    <h5 className="card-title">{rule.title}</h5>
                    <h6 className="mb-0 text-success">
                      {formatPrice(rule.price)} تومان
                    </h6>
                  </div>

                  <div className="card-footer w-100 text-center p-0">
                    <Button btnClass="w-100 btn-primary-soft mb-0">
                      <Fragment>
                        <i className="bi bi-cart me-2"></i>افزودن به سبد
                      </Fragment>
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        </div>
      </Fragment>
  );
};

export default Rules;
