import React from "react";

const Pagination = () => {
  return (
    <nav className="my-5" aria-label="navigation">
      <ul className="pagination d-inline-block d-md-flex justify-content-center">
        <li className="page-item disabled">
          <a className="page-link" href="#" tabIndex={-1} aria-disabled="true">
            قبل
          </a>
        </li>
        <li className="page-item active">
          <a className="page-link" href="#">
            1
          </a>
        </li>
        <li className="page-item">
          <a className="page-link" href="#">
            2
          </a>
        </li>
        <li className="page-item disabled">
          <a className="page-link" href="#">
            ...
          </a>
        </li>
        <li className="page-item">
          <a className="page-link" href="#">
            15
          </a>
        </li>
        <li className="page-item">
          <a className="page-link" href="#">
            بعد
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
