import React, { type FC } from "react";
import { Route, Routes } from "react-router-dom";

import MainLayout from "./Layout/MainLayout";

import {
  Landing,
  AboutUs,
  Rules,
  Blog,
  Post,
  Gallery,
  Album,
  Cart,
  ContactUs,
  Profile,
  NotFound,
  MyRules,
  MyEvents,
  Event,
  Events,
  Coachs,
  Coach,
} from "../Pages";

const Authenticated: FC = () => {
  return (
    <MainLayout>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/rules" element={<Rules />} />

        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id/" element={<Post />} />

        <Route path="/gallery" element={<Gallery />} />
        <Route path="/gallery/:id/" element={<Album />} />
        
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />

        <Route path="/events" element={<Events />} />
        <Route path="/events/:id/" element={<Event />} />

        <Route path="/coachs" element={<Coachs />} />
        <Route path="/coachs/:id/" element={<Coach />} />

        <Route path="*" element={<NotFound />} />

        {/* ============================================== */}

        <Route path="/cart" element={<Cart />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/my-rules" element={<MyRules />} />
        <Route path="/my-events" element={<MyEvents />} />        
      </Routes>
    </MainLayout>
  );
};

export default Authenticated;
