import React, { Fragment, useContext, useEffect, useState } from "react";

import { PageHeader } from "../Components/Common";

import { EventsListType, EventCategory } from "../Structures";
import { Pagination } from "../Components/Base";
import { LoadingContext } from "../Context";
import {
  TransactionGetEventCategories,
  TransactionGetEvents,
} from "../Services/Transactions";
import { formatDate, formatText } from "../Utils";

const Events = () => {
  const { setLoading } = useContext(LoadingContext);

  // =======================================================================

  const [events, setEvents] = useState<EventsListType[]>([]);
  const [eventCategories, setEventCategories] = useState<EventCategory[]>([]);

  // =======================================================================

  useEffect(() => {
    setLoading(true);
    TransactionGetEvents()
      .then(({ data: res }) => {
        if (res.transaction_status) {
          setEvents(res.data || []);
        }
      })
      .finally(() => setLoading(false));
    TransactionGetEventCategories()
      .then(({ data: res }) => {
        if (res.transaction_status) {
          setEventCategories(res.data || []);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  // =======================================================================

  return (
    <Fragment>
      <PageHeader title="رویداد ها" />
      <section className="position-relative pt-0">
        <div className="container" data-sticky-container>
          <div className="row">
            <div className="col-lg-9">
              <div className="row gy-4">
                {events.map((event: EventsListType) => (
                  <div className="col-sm-6">
                    <div className="card">
                      <div className="position-relative">
                        <img
                          className="card-img"
                          src="/imgs/blog/4by3/01.jpg"
                          alt="Card image"
                        />
                        <div className="card-img-overlay d-flex align-items-start flex-column p-3">
                          <div className="w-100 mt-auto">
                            {event.categories.map((category: EventCategory) => (
                              <span
                                className="badge text-bg-warning me-1 mb-2"
                                key={category.id}
                              >
                                <i className="fas fa-circle me-2 small fw-bold"></i>
                                {category.title}
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="card-body px-0 pt-3">
                        <h4 className="card-title">
                          <span className="btn-link text-reset fw-bold">
                            {event.title}
                          </span>
                        </h4>
                        <p className="card-text">
                          {formatText(event.description, 40)}
                        </p>

                        <ul className="nav nav-divider align-items-center d-none d-sm-inline-block">
                          <li className="nav-item">
                            تاریخ:‌ {formatDate(event.date)}
                          </li>
                          <span className="fw-bolder mx-2">-</span>
                          <li className="nav-item">ساعت: {event.time}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}

                <Pagination />
              </div>
            </div>

            <div className="col-lg-3 mt-5 mt-lg-0">
              <div data-sticky data-margin-top="80" data-sticky-for="767">
                <div>
                  <h4 className="mb-3">دسته بندی</h4>

                  <div
                    className="text-center mb-3 card-bg-scale position-relative overflow-hidden rounded"
                    style={{
                      backgroundImage: "url(/imgs/blog/4by3/01.jpg)",
                      backgroundPosition: "center left",
                      backgroundSize: "cover",
                    }}
                  >
                    <div className="bg-dark-overlay-4 p-3">
                      <a
                        href="#"
                        className="stretched-link btn-link text-white h5"
                      >
                        گردشگری
                      </a>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-sm-6 col-lg-12">
                    <h4 className="mt-4 mb-3">آخرین اخبار</h4>

                    <div className="card mb-3">
                      <div className="row g-3">
                        <div className="col-4">
                          <img
                            className="rounded"
                            src="/imgs/blog/4by3/thumb/01.jpg"
                            alt=""
                          />
                        </div>
                        <div className="col-8">
                          <h6>
                            <a
                              href="post-single-2.html"
                              className="btn-link stretched-link text-reset"
                            >
                              تداوم تنفس هوای ناسالم‌
                            </a>
                          </h6>
                          <div className="small mt-1">17 دی، 1400</div>
                        </div>
                      </div>
                    </div>

                    <div className="card mb-3">
                      <div className="row g-3">
                        <div className="col-4">
                          <img
                            className="rounded"
                            src="/imgs/blog/4by3/thumb/02.jpg"
                            alt=""
                          />
                        </div>
                        <div className="col-8">
                          <h6>
                            <a
                              href="post-single-2.html"
                              className="btn-link stretched-link text-reset"
                            >
                              جدول لیگ در پایان هفته
                            </a>
                          </h6>
                          <div className="small mt-1">4 مهر، 1400</div>
                        </div>
                      </div>
                    </div>

                    <div className="card mb-3">
                      <div className="row g-3">
                        <div className="col-4">
                          <img
                            className="rounded"
                            src="/imgs/blog/4by3/thumb/03.jpg"
                            alt=""
                          />
                        </div>
                        <div className="col-8">
                          <h6>
                            <a
                              href="post-single-2.html"
                              className="btn-link stretched-link text-reset"
                            >
                              گشت نامحسوس در بازار ارز
                            </a>
                          </h6>
                          <div className="small mt-1">30 تیر، 1400</div>
                        </div>
                      </div>
                    </div>

                    <div className="card mb-3">
                      <div className="row g-3">
                        <div className="col-4">
                          <img
                            className="rounded"
                            src="/imgs/blog/4by3/thumb/04.jpg"
                            alt=""
                          />
                        </div>
                        <div className="col-8">
                          <h6>
                            <a
                              href="post-single-2.html"
                              className="btn-link stretched-link text-reset"
                            >
                              7 مشکل اولیه استارت آپ ها
                            </a>
                          </h6>
                          <div className="small mt-1">29 آذر، 1400</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Events;
