export const formatDate = (date: string) => {
  const dateSections = date.split("/");

  const persianMonthList = [
    "فروردین",
    "اردیبهشت",
    "خرداد",
    "تیر",
    "مرداد",
    "شهریور",
    "مهر",
    "آبان",
    "آذر",
    "دی",
    "بهمن",
    "اسفند",
  ];

  const monthName = persianMonthList[Number(dateSections[1]) - 1];

  return `${dateSections[dateSections.length - 1]} ${monthName} ${
    dateSections[0]
  }`;
};
