import { PageHeader } from "../Components/Common";
import { Button, Pagination } from "../Components/Base";
import { LoadingContext } from "../Context";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { TransactionGetCoachs } from "../Services/Transactions";
import { CoachsListType } from "../Structures";

const Coachs = () => {
  const navigate = useNavigate();
  const { setLoading } = useContext(LoadingContext);

  // ======================================================================

  const [coachsList, setCoachsList] = useState<CoachsListType[]>([]);

  // ======================================================================

  const handleClickVisitProfile = (coachId: number) => {
    navigate(`/coachs/${coachId}`);
  };

  // ======================================================================

  useEffect(() => {
    setLoading(true);
    TransactionGetCoachs()
      .then(({ data: res }) => {
        if (res.transaction_status) {
          setCoachsList(res.data || []);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  // ======================================================================

  return (
    <Fragment>
      <PageHeader title="مربی‌ها" />
      <section className="position-relative pt-0">
        <div className="container" data-sticky-container>
          <div className="row">
            <div className="col-12">
              <div className="row gy-4">
                {coachsList.map((coach: CoachsListType) => (
                  <div className="col-sm-4">
                    <div className="card p-3 border rounded-3">
                      <div className="position-relative">
                        <img
                          className="card-img"
                          src="/imgs/blog/4by3/01.jpg"
                          alt="Card image"
                        />
                      </div>
                      <div className="card-body px-0 pt-3">
                        <h4 className="card-title">
                          <a
                            href="post-single-4.html"
                            className="btn-link text-reset"
                          >
                            فیلم‌های بالیوودی الگوی ضدانقلاب شده!
                          </a>
                        </h4>
                        <p className="card-text text-justify">
                          پیوسته اهل دنیای موجود طراحی اساسا...
                        </p>
                        <Button
                          btnClass="w-100 btn-primary-soft m-0"
                          onClick={() => handleClickVisitProfile(coach.id)}
                          text="مشاهده پروفایل"
                        />
                      </div>
                    </div>
                  </div>
                ))}

                <Pagination />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Coachs;
