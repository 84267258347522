import { createContext, Dispatch, SetStateAction } from "react";
import type { SettingType } from "../Structures";

type MainContextType = {
  setting: SettingType;
  setSetting: Dispatch<SetStateAction<SettingType>>;
};

const MainContext = createContext<MainContextType>({
  setting: {
    cart_sheba: "",
    cart_number: "",
    account_number: "",
    owner_cart: "",
    officePhone: "",
    phone: "",
    workTime: "",
    address: "",
    email: "",
    copyright: "",
    telegramLink: "",
    whatsappLink: "",
    instagramLink: "",
    mapLink: "",
  },
  setSetting: () => {},
});

export default MainContext;
