import React, { Fragment, useContext, useEffect, useState } from "react";

import { TransactionGetGallery } from "../Services/Transactions";

import { LoadingContext } from "../Context";

import { GalleryType } from "../Structures";

import { formatDate, formatText } from "../Utils";

import { PageHeader } from "../Components/Common";
import { Pagination } from "../Components/Base";
import { Link } from "react-router-dom";

const Gallery = () => {
  const { setLoading } = useContext(LoadingContext);

  // ==============================================================

  const [albums, setAlbums] = useState<GalleryType[]>([]);

  // ==============================================================

  useEffect(() => {
    setLoading(true);
    TransactionGetGallery()
      .then(({ data: res }) => {
        if (res.transaction_status) {
          setAlbums(res.data || []);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  // ==============================================================

  return (
    <Fragment>
      <PageHeader title="گالری" />
      <section className="position-relative pt-0">
        <div className="container" data-sticky-container>
          <div className="row">
            {albums.map((album: GalleryType) => (
              <div className="col-lg-6">
                <Link to={`/gallery/${album.id}/`} className="card shadow border-1 p-2 mb-4">
                  <div className="row">
                    <div className="col-md-5">
                      <img
                        className="rounded-3"
                        src={album.thumbnail}
                        alt={album.title}
                      />
                    </div>
                    <div className="col-md-7 mt-3 mt-md-0">
                      <a href="#" className="badge text-bg-danger mb-2">
                        <i className="fas fa-circle me-2 small fw-bold"></i>فیلم
                      </a>
                      <h4>
                        <span className="btn-link stretched-link text-reset">
                          {album.title}
                        </span>
                      </h4>

                      <p>{formatText(album.description, 50)}</p>

                      <ul className="nav nav-divider align-items-center d-none d-sm-inline-block">
                        <li className="nav-item">
                          {formatDate(album.createDate)}
                        </li>
                      </ul>
                    </div>
                  </div>
                </Link>
              </div>
            ))}

            <Pagination />
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Gallery;
