import axios, { AxiosError } from "axios";
import config from "./config.json";

import Toast from "../Utils/Toast";

const apiClient = axios.create({
  baseURL: config.serverUrl
});

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("Token");

  if (token) config.headers["Authorization"] = `Bearer ${token}`;

  return config;
}, null);

apiClient.interceptors.response.use(null, (error: AxiosError) => {
    const expectedErrors = error.response && error.response.status >= 400 && error.response.status < 500;

    if (!expectedErrors) {
        Toast("error", "مشکلی از سمت سرور پیش آمده است!");
    }

    return Promise.reject(error);
  }
);

export default apiClient;

