import EmptyCart from "../Components/Pages/Cart/EmptyCart";
import React, { useState } from "react";

const Cart = () => {
  const [products, setProducts] = useState([]);

  return products ? <EmptyCart /> : <h1>همش گوشته</h1>;
};

export default Cart;
