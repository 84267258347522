import React, { Fragment } from "react";

const Coach = () => {
  return (
    <Fragment>
      <section className="pt-4">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="bg-primary bg-opacity-10 d-md-flex p-3 p-sm-4 my-3 text-center text-md-start rounded">
                <div className=" me-0 me-md-4">
                  <div className="avatar avatar-xxl">
                    <img
                      className="avatar-img rounded-circle"
                      src="/imgs/avatar/02.jpg"
                      alt="avatar"
                    />
                  </div>

                  <div className="text-center mt-n3 position-relative">
                    <span className="badge bg-danger fs-6">6 خبر</span>
                  </div>
                </div>

                <div>
                  <h2 className="m-0">علیرضا اکبری</h2>
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <i className="bi bi-person-fill me-1"></i> سردبیر
                    </li>
                    <li className="list-inline-item">
                      <i className="bi bi-geo-alt me-1"></i> نیویورک
                    </li>
                  </ul>
                  <p className="my-2">
                    در این صورت می توان امید داشت که تمام و دشواری موجود در
                    ارائه راهکارها و شرایط سخت تایپ به پایان رسد وزمان مورد نیاز
                    شامل حروفچینی دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل
                    دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
                  </p>

                  <ul className="nav justify-content-center justify-content-md-start">
                    <li className="nav-item">
                      <a className="nav-link ps-0 pe-2 fs-5" href="#">
                        <i className="fab fa-facebook-square"></i>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link px-2 fs-5" href="#">
                        <i className="fab fa-twitter-square"></i>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link px-2 fs-5" href="#">
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Coach;
