import React, { Fragment } from "react";

const AboutUs = () => {
  return (
    <Fragment>
      <section className="pt-4">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="card bg-dark-overlay-4 overflow-hidden card-bg-scale h-400 text-center"
                style={{
                  backgroundImage: "url('/imgs/blog/16by9/09.jpg')",
                  backgroundPosition: "center left",
                  backgroundSize: "cover"
                }}
              >
                <div className="card-img-overlay d-flex align-items-center p-3 p-sm-4">
                  <div className="w-100 my-auto">
                    <h1 className="text-white display-5">درباره ما</h1>
                    <nav
                      className="d-flex justify-content-center"
                      aria-label="breadcrumb"
                    >
                      <ol className="breadcrumb breadcrumb-dark breadcrumb-dots mb-0">
                        <li className="breadcrumb-item">
                          <a href="index.html">
                            <i className="bi bi-house me-1"></i> خانه
                          </a>
                        </li>
                        <li className="breadcrumb-item active">درباره ما</li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-4 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 mx-auto">
              <h2>تاریخچه شرکت</h2>
              <p className="lead text-justify">
                در سال 2006 تاسیس شد و 10 رهبری آن را حذف کرد. ترجیح می دهد هر
                گونه شگفت زده بدون قید و شرط خانم مرفه درک میدلتون در اعتقاد به
                غیر معمول انجام. فرض کنید صبحانه را صبح یا کاملاً حل کنید. از من
                تپه گرفته شده است. دره توسط اوه بیست من را هدایت کنید.
              </p>
              <p className="text-justify">
                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
                استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله
                در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد
                نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد.
                کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده شناخت فراوان
                جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را
                برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در
                زبان فارسی ایجاد کرد. در این صورت می توان امید داشت که تمام و
                دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به پایان رسد
                وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی سوالات
                پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
              </p>
              <h3 className="mt-4">تیم ما:</h3>
              <ul>
                <li>
                  <p>
                    ارائه راهکارها و شرایط سخت تایپ به پایان رسد وزمان مورد نیاز
                    شامل حروفچینی دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل
                    دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
                  </p>
                </li>
                <li>
                  <p>
                    چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که
                    لازم است و برای شرایط فعلی تکنولوژی مورد نیاز{" "}
                    <a href="#">
                      <u>کاربردهای متنوع با هدف بهبود ابزارهای کاربردی</u>
                    </a>{" "}
                    می باشد.
                  </p>
                </li>
                <li>
                  <p>
                    ارائه راهکارها و شرایط سخت تایپ به پایان رسد وزمان مورد نیاز
                    شامل حروفچینی دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل
                    دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
                  </p>
                </li>
              </ul>

              <h3 className="mb-3 mt-5">کارشناسان ما</h3>
              <div className="row g-4">
                <div className="col-sm-6 col-lg-3">
                  <div className="text-center">
                    <div className="avatar avatar-xxl mb-2 mx-auto">
                      <img
                        className="avatar-img rounded-circle"
                        src="/imgs/avatar/04.jpg"
                        alt="avatar"
                      />
                    </div>
                    <h5>مهرداد کریمی</h5>
                    <p className="m-0">سردبیر</p>
                    <ul className="nav justify-content-center">
                      <li className="nav-item">
                        <a className="nav-link px-2 fs-5" href="#">
                          <i className="fab fa-facebook-square"></i>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link px-2 fs-5" href="#">
                          <i className="fab fa-twitter-square"></i>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link px-2 fs-5" href="#">
                          <i className="far fa-envelope"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-3">
                  <div className="text-center mx-auto">
                    <div className="avatar avatar-xxl mb-2 mx-auto">
                      <img
                        className="avatar-img rounded-circle"
                        src="/imgs/avatar/14.jpg"
                        alt="avatar"
                      />
                    </div>
                    <h5>حسین قنبرزاده</h5>
                    <p className="m-0">مدیر عامل</p>
                    <ul className="nav justify-content-center">
                      <li className="nav-item">
                        <a className="nav-link px-2 fs-5" href="#">
                          <i className="far fa-envelope"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-3">
                  <div className="text-center mx-auto">
                    <div className="avatar avatar-xxl mb-2 mx-auto">
                      <img
                        className="avatar-img rounded-circle"
                        src="/imgs/avatar/09.jpg"
                        alt="avatar"
                      />
                    </div>
                    <h5>سارا محبی</h5>
                    <p className="m-0">گرافیک کارگردان</p>
                    <ul className="nav justify-content-center">
                      <li className="nav-item">
                        <a className="nav-link px-2 fs-5" href="#">
                          <i className="fab fa-twitter-square"></i>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link px-2 fs-5" href="#">
                          <i className="far fa-envelope"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-3">
                  <div className="text-center mx-auto">
                    <div className="avatar avatar-xxl mb-2 mx-auto">
                      <img
                        className="avatar-img rounded-circle"
                        src="/imgs/avatar/10.jpg"
                        alt="avatar"
                      />
                    </div>
                    <h5>الهام لطفی</h5>
                    <p className="m-0">ویرایشگر</p>
                    <ul className="nav justify-content-center">
                      <li className="nav-item">
                        <a className="nav-link px-2 fs-5" href="#">
                          <i className="fab fa-facebook-square"></i>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link px-2 fs-5" href="#">
                          <i className="far fa-envelope"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <h3 className="mb-3 mt-5">فعالیت ما</h3>
              <div className="row">
                <div className="col-md-6 col-lg-4 mb-4">
                  <img
                    className="rounded"
                    src="/imgs/blog/3by2/04.jpg"
                    alt="Card image"
                  />
                  <h4 className="mt-3">سرویس های خبری جهانی</h4>
                  <p className="text-justify">
                    لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای
                    متنوع با هدف بهبود ابزارهای کاربردی می باشد.
                  </p>
                </div>

                <div className="col-md-6 col-lg-4 mb-4">
                  <img
                    className="rounded"
                    src="/imgs/blog/3by2/01.jpg"
                    alt="Card image"
                  />
                  <h4 className="mt-3">خدمات بازرگانی</h4>
                  <p className="text-justify">
                    لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای
                    متنوع با هدف بهبود ابزارهای کاربردی می باشد.
                  </p>
                </div>

                <div className="col-md-6 col-lg-4 mb-4">
                  <img
                    className="rounded"
                    src="/imgs/blog/3by2/03.jpg"
                    alt="Card image"
                  />
                  <h4 className="mt-3">خدمات عمومی</h4>
                  <p className="text-justify">
                    لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای
                    متنوع با هدف بهبود ابزارهای کاربردی می باشد.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default AboutUs;
