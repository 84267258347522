import React, { Fragment, useContext, useEffect, useState } from "react";

import { LoadingContext } from "../Context";
import { PostCategoryType, PostListType } from "../Structures/Types/post";
import { TransactionGetBlog } from "../Services/Transactions";

import { formatDate, formatText } from "../Utils";
import { Link } from "react-router-dom";
import { PageHeader } from "../Components/Common";

const Blog = () => {
  const { setLoading } = useContext(LoadingContext);
  const [posts, setPosts] = useState<PostListType[]>([]);

  // ==============================================================================

  useEffect(() => {
    setLoading(true);
    TransactionGetBlog()
      .then(({ data: res }) => {
        if (res.transaction_status) {
          setPosts(res.data || []);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  // ==============================================================================

  return (
    <Fragment>
      <PageHeader title="اخبار"/>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="row gy-4">
              {posts.map((post: PostListType) => (
                <div className="col-sm-6 col-lg-4" key={post.id}>
                  <Link to={`/blog/${post.id}/`} className="card">
                    <div className="position-relative">
                      <img
                        className="card-img"
                        src={post.poster}
                        alt={post.title}
                      />
                      <div className="card-img-overlay d-flex align-items-start flex-column p-3">
                        <div className="w-100 mt-auto">
                          {post.categories.map((category: PostCategoryType) => (
                            <div
                              key={category.id}
                              className={`badge text-bg-${category.color} mb-2 me-1`}
                            >
                              <i className="fas fa-circle me-2 small fw-bold"></i>
                              {category.title}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="card-body px-0 pt-3">
                      <h4 className="card-title">
                        <h5
                          className="btn-link fw-bold text-reset"
                        >
                          {post.title}
                        </h5>
                      </h4>
                      <p className="card-text text-justify">
                        {formatText(post.description, 100)}
                      </p>

                      <ul className="nav nav-divider align-items-center d-none d-sm-inline-block">
                        <li className="nav-item">
                          {formatDate(post.timeStamp)}
                        </li>
                      </ul>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Blog;
