import { LoadingContext } from "../Context";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { BaseRouteParams, PostType } from "../Structures";
import { TransactionGetPost } from "../Services/Transactions";
import Toast from "../Utils/Toast";

const Post = () => {
  const params: BaseRouteParams = useParams();
  const navigate = useNavigate();

  const { setLoading } = useContext(LoadingContext);

  // ===========================================================

  const [post, setPost] = useState<PostType>({
    categories: [],
    comments: [],
    contents: [],
    description: "",
    poster: "",
    related_posts: [],
    thumbnail: "",
    timeStamp: "",
    title: ""
  });

  // ===========================================================

  useEffect(() => {
    if (params.id) {
      setLoading(true);
      TransactionGetPost(params.id)
        .then(({ data: res }) => {
          if (res.transaction_status) {
            setPost(res.data!)
          } else {
            navigate("/blog");
            Toast("error", "پست مورد نظر یافت نشد.");
          }
        })
        .finally(() => setLoading(false));
    } else navigate("/blog");
  }, []);

  // ===========================================================

  return (
    <Fragment>
      <section
        className="bg-dark-overlay-4"
        style={{
          backgroundImage: "url(/img/blog/16by9/big/02.jpg)",
          backgroundPosition: "center left",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 py-md-5 my-lg-5">
              <a href="#" className="badge text-bg-warning mb-2">
                <i className="fas fa-circle me-2 small fw-bold"></i>گردشگری
              </a>
              <a href="#" className="badge text-bg-primary mb-2">
                <i className="fas fa-circle me-2 small fw-bold"></i>رسانه
              </a>
              <h1 className="text-white">
                آشنایی با کلید موفقیت نهضت ملی مسکن
              </h1>
              <p className="lead text-white">
                طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت
                می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها و
                شرایط سخت تایپ به پایان رسد وزمان مورد نیاز شامل حروفچینی
                دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی
                اساسا مورد استفاده قرار گیرد.
              </p>

              <ul className="nav nav-divider text-white-force align-items-center">
                <li className="nav-item">
                  <div className="nav-link">
                    <div className="d-flex align-items-center text-white position-relative">
                      <div className="avatar avatar-sm">
                        <img
                          className="avatar-img rounded-circle"
                          src="assets/images/avatar/12.jpg"
                          alt="avatar"
                        />
                      </div>
                      <span className="ms-3">
                        با{" "}
                        <a
                          href="#"
                          className="stretched-link text-reset btn-link"
                        >
                          مریم حسینی
                        </a>
                      </span>
                    </div>
                  </div>
                </li>
                <li className="nav-item">15 آذر، 1400</li>
                <li className="nav-item">5 دقیقه زمان مطالعه</li>
                <li className="nav-item">
                  <i className="far fa-eye me-1"></i> 2344 بازدید
                </li>
                <li className="nav-item">
                  <a href="#">
                    <i className="fas fa-heart me-1 text-danger"></i>
                  </a>{" "}
                  266
                </li>
              </ul>

              <div className="d-md-flex align-items-center mt-4">
                <h5 className="text-white me-3">اشتراک گذاری </h5>
                <ul className="nav text-white-force">
                  <li className="nav-item">
                    <a
                      className="nav-link icon-md rounded-circle me-2 mb-2 p-0 fs-5 bg-facebook"
                      href="#"
                    >
                      <i className="fab fa-facebook-square align-middle"></i>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link icon-md rounded-circle me-2 mb-2 p-0 fs-5 bg-twitter"
                      href="#"
                    >
                      <i className="fab fa-twitter-square align-middle"></i>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link icon-md rounded-circle me-2 mb-2 p-0 fs-5 bg-linkedin"
                      href="#"
                    >
                      <i className="fab fa-linkedin align-middle"></i>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link icon-md rounded-circle me-2 mb-2 p-0 fs-5 bg-pinterest"
                      href="#"
                    >
                      <i className="fab fa-pinterest align-middle"></i>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link icon-md rounded-circle me-2 mb-2 p-0 fs-5 bg-primary"
                      href="#"
                    >
                      <i className="far fa-envelope align-middle"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container position-relative" data-sticky-container>
          <div className="row">
            <div className="col-lg-9 mb-5">
              <p>
                <span className="dropcap">A</span>ایپسوم متن ساختگی با تولید
                سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است.
                چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم
                است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با
                هدف بهبود ابزارهای کاربردی می باشد.
              </p>
              <p>
                دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی
                اساسا مورد استفاده قرار گیرد.{" "}
                <a href="#" className="text-reset">
                  <u>روزنامه فارس</u>
                </a>{" "}
                استفاده از این متن تستی می تواند سرعت پیشرفت پروژه را افزایش
                دهد، و طراحان به جای تایپ و نگارش متن می توانند تنها با یک کپی و
                پست این متن را در کادرهای مختلف جایگزین نمائید. این نوشته توسط
                سایت لورم ایپسوم فارسی نگاشته شده است.
              </p>

              <p>
                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
                استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله
                در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد
                نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد.
                کتابهای زیادی{" "}
                <img
                  src="assets/images/blog/4by3/thumb/04.jpg"
                  className="rounded float-start me-3 my-3"
                  alt="..."
                />{" "}
                استفاده از این متن تستی می تواند سرعت پیشرفت پروژه را افزایش
                دهد، و طراحان به جای تایپ و نگارش متن می توانند تنها با یک کپی و
                پست این متن را در کادرهای مختلف جایگزین نمائید. این نوشته توسط
                سایت لورم ایپسوم فارسی نگاشته شده است. این یک نوشته آزمایشی است
                که به طراحان و برنامه نویسان کمک میکند تا این عزیزان با بهره
                گیری از این نوشته تستی و آزمایشی بتوانند نمونه تکمیل شده از
                پروژه و طرح خودشان را به کارفرما نمایش دهند، استفاده از این متن
                تستی می تواند سرعت پیشرفت پروژه را افزایش دهد، و طراحان به جای
                تایپ و نگارش متن می توانند تنها با یک کپی و پست این متن را در
                کادرهای مختلف جایگزین نمائید.
                <u>
                  {" "}
                  ممکن است طولانی‌ تر در یک حباب صابون می‌ دمیم تا بزرگتر شود،
                  گر چه با قطعیتی تمام می‌ دانیم که خواهد ترکید.
                </u>{" "}
                در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه
                راهکارها و شرایط سخت تایپ به پایان رسد وزمان مورد نیاز شامل
                حروفچینی دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل دنیای موجود
                طراحی اساسا مورد استفاده قرار گیرد.{" "}
              </p>

              <p>
                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
                استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله
                در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد
                نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد.
                کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده شناخت فراوان
                جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را
                برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در
                زبان فارسی ایجاد کرد. در این صورت می توان امید داشت که تمام و
                دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به پایان رسد
                وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی سوالات
                پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.{" "}
              </p>

              <div className="text-center h5 mb-4">. . .</div>

              <div className="row g-2">
                <div className="col-md-6">
                  <a
                    href="assets/images/blog/1by1/06.jpg"
                    data-glightbox
                    data-gallery="image-popup"
                  >
                    <img
                      className="rounded"
                      src="assets/images/blog/1by1/06.jpg"
                      alt="Image"
                    />
                  </a>
                </div>
                <div className="col-md-6">
                  <a
                    href="assets/images/blog/1by1/07.jpg"
                    data-glightbox
                    data-gallery="image-popup"
                  >
                    <img
                      className="rounded"
                      src="assets/images/blog/1by1/07.jpg"
                      alt="Image"
                    />
                  </a>
                </div>
                <div className="col-12">
                  <figure className="figure">
                    <a
                      href="assets/images/blog/16by9/05.jpg"
                      data-glightbox
                      data-gallery="image-popup"
                    >
                      <img
                        className="rounded"
                        src="assets/images/blog/16by9/05.jpg"
                        alt="Image"
                      />
                    </a>
                    <figcaption className="figure-caption text-center">
                      (عکاس:{" "}
                      <a className="text-reset" href="#">
                        pexels.com
                      </a>
                      )
                    </figcaption>
                  </figure>
                </div>
              </div>
              <p>
                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
                استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله
                در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد
                نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد.
                کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده شناخت فراوان
                جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را
                برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در
                زبان فارسی ایجاد کرد. در این صورت می توان امید داشت که تمام و
                دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به پایان رسد
                وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی سوالات
                پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
              </p>

              <h3 className="mt-4">شعار مولد در مورد تجارت</h3>
              <div className="row mb-4">
                <div className="col-md-6">
                  <p>
                    چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که
                    لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای
                    متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی
                    در شصت و سه درصد گذشته، حال و آینده شناخت فراوان جامعه و
                    متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای
                    طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در
                    زبان فارسی ایجاد کرد. در این صورت می توان امید داشت.
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی
                    الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد.
                    در این صورت می توان امید داشت که تمام و دشواری موجود در
                    ارائه راهکارها و شرایط سخت تایپ به پایان رسد وزمان مورد نیاز
                    شامل حروفچینی دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل
                    دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.
                  </p>
                </div>
              </div>
              <p>
                در نهایت این مرگ است که باید پیروز شود، زیرا از هنگام تولد بخشی
                از سرنوشت ما شده و فقط مدت کوتاهی پیش از بلعیدن طعمه اش، با آن
                بازی می کند. با این همه، ما تا آنجا که ممکن است، با علاقه فراوان
                و دلواپسی زیاد به زندگی ادامه می دهیم، همان‌ طور که تا آنجا که
                ممکن است طولانی‌ تر در یک حباب صابون می‌ دمیم تا بزرگتر شود، گر
                چه با قطعیتی تمام می‌ دانیم که خواهد ترکید.
              </p>

              <figure className="bg-light p-3 p-md-5 my-5">
                <blockquote className="blockquote">
                  <p>
                    گواهی است که در آن بازیِ بود و نمود هیچ‌ جایی ندارد. پس من
                    در مسرتِ عشق ورزیدن به یک ناشناس غرق می‌شوم، کسی که تا ابد
                    ناشناس خواهد ماند. سِیری عارفانه: من آن‌چه را نمی‌شناسم
                    می‌شناسم...!
                  </p>
                </blockquote>
                <figcaption className="blockquote-footer">
                  حسین صادقی
                </figcaption>
              </figure>

              <p>
                {" "}
                این راست نیست که هرچه عاشق‌ تر باشی بهتر درک می‌کنی. همه‌ی آنچه
                عشق و عاشقی از من می‌ خواهد فقط درکِ این حکمت است: دیگری
                نشناختنی است؛ ماتیِ او پرده‌ی ابهامی به روی یک راز نیست، بل
                گواهی است که در آن بازیِ بود و نمود هیچ‌ جایی ندارد.{" "}
              </p>

              <h3>چرا بوت استرپ اینقدر معروف است؟</h3>
              <p>
                با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص
                طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت
                می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها و
                شرایط سخت تایپ به پایان رسد وزمان مورد نیاز شامل حروفچینی
                دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی
                اساسا مورد استفاده قرار گیرد.
              </p>

              <div className="text-center h5 mb-4">. . .</div>

              <h3>معرفی فرم ورک های برتر جهان</h3>
              <p>
                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
                استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله
                در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد
                نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد.
                کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده شناخت فراوان
                جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را
                برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در
                زبان فارسی ایجاد کرد.
              </p>

              <div className="row g-0 my-3">
                <div className="col-sm-6 border p-3 p-md-4 position-relative rounded-start">
                  <div className="d-flex align-items-center">
                    <div className="bg-primary bg-opacity-10 h-auto align-items-center d-flex align-self-stretch">
                      <i className="bi bi-chevron-compact-right fs-3 text-primary px-1"></i>
                    </div>

                    <div className="col-3 d-none d-md-block">
                      <img src="assets/images/blog/4by3/04.jpg" alt="Image" />
                    </div>

                    <div className="ms-3">
                      <h5 className="m-0">
                        {" "}
                        <a
                          href="post-single-5.html"
                          className="stretched-link btn-link text-reset"
                        >
                          دلیل کاهش نرخ دلار
                        </a>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 border p-3 p-md-4 position-relative text-sm-end rounded-end">
                  <div className="d-flex align-items-center">
                    <div className="me-3">
                      <h5 className="m-0">
                        {" "}
                        <a
                          href="post-single-5.html"
                          className="stretched-link btn-link text-reset"
                        >
                          جدول لیگ در پایان هفته
                        </a>
                      </h5>
                    </div>

                    <div className="col-3 d-none d-md-block">
                      <img src="assets/images/blog/4by3/05.jpg" alt="Image" />
                    </div>

                    <div className="bg-primary bg-opacity-10 h-auto align-items-center d-flex align-self-stretch">
                      <i className="bi bi-chevron-compact-left fs-3 text-primary px-1"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex p-2 p-md-4 my-3 bg-primary bg-opacity-10 rounded">
                <a href="#">
                  <div className="avatar avatar-xxl me-2 me-md-4">
                    <img
                      className="avatar-img rounded-circle"
                      src="assets/images/avatar/12.jpg"
                      alt="avatar"
                    />
                  </div>
                </a>

                <div>
                  <div className="d-sm-flex align-items-center justify-content-between">
                    <div>
                      <h4 className="m-0">
                        <a href="#" className="text-reset">
                          مریم حسینی
                        </a>
                      </h4>
                      <small>روزنامه نگار سایت Blogzine</small>
                    </div>
                    <a href="#" className="btn btn-xs btn-primary-soft">
                      مشاهده اخبار
                    </a>
                  </div>
                  <p className="my-2">
                    مریم حسینی سردبیر ارشد این وبلاگ است و همچنین اخبار فوری
                    مستقر در لندن را گزارش می دهد. او از سال 2015 درباره دولت،
                    عدالت کیفری و نقش پول در سیاست نوشته است.
                  </p>

                  <ul className="nav">
                    <li className="nav-item">
                      <a className="nav-link ps-0 pe-2 fs-5" href="#">
                        <i className="fab fa-facebook-square"></i>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link px-2 fs-5" href="#">
                        <i className="fab fa-twitter-square"></i>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link px-2 fs-5" href="#">
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="mt-5">
                <h3>5 دیدگاه</h3>

                <div className="my-4 d-flex">
                  <img
                    className="avatar avatar-md rounded-circle float-start me-3"
                    src="assets/images/avatar/01.jpg"
                    alt="avatar"
                  />
                  <div>
                    <div className="mb-2">
                      <h5 className="m-0">شادی اسدی</h5>
                      <span className="me-3 small">
                        21 خرداد، 1400 در 3:00 بعد از ظهر{" "}
                      </span>
                      <a href="#" className="text-body fw-normal">
                        پاسخ
                      </a>
                    </div>
                    <p>
                      سایهٔ نیرومندی و ثروت خیال می‌ کنند که می‌توانند در خارج
                      از وطن خود زندگی نمایند و خوشبخت و سرافراز باشند ولی به
                      زودی می‌ فهمند که اشتباه کرده‌ اند و عظمت هر ملتی بر روی
                      خرابه‌ های وطن خودش می‌باشد و بس!
                    </p>
                  </div>
                </div>

                <div className="my-4 d-flex ps-2 ps-md-3">
                  <img
                    className="avatar avatar-md rounded-circle float-start me-3"
                    src="assets/images/avatar/02.jpg"
                    alt="avatar"
                  />
                  <div>
                    <div className="mb-2">
                      <h5 className="m-0">علی قنبرزاده</h5>
                      <span className="me-3 small">
                        21 خرداد، 1400 در 3:00 بعد از ظهر{" "}
                      </span>
                      <a href="#" className="text-body fw-normal">
                        پاسخ
                      </a>
                    </div>
                    <p>
                      وقتی ثروت‌ های بزرگ به دست برخی مردم می‌افتد در پرتو آن
                      نیرومند می‌شوند و در سایهٔ نیرومندی و ثروت خیال می‌ کنند
                      که می‌توانند در خارج از وطن خود زندگی نمایند و خوشبخت و
                      سرافراز باشند.
                    </p>
                  </div>
                </div>

                <div className="my-4 d-flex ps-3 ps-md-5">
                  <img
                    className="avatar avatar-md rounded-circle float-start me-3"
                    src="assets/images/avatar/01.jpg"
                    alt="avatar"
                  />
                  <div>
                    <div className="mb-2">
                      <h5 className="m-0">مونا شاه حسینی</h5>
                      <span className="me-3 small">
                        21 خرداد، 1400 در 3:00 بعد از ظهر
                      </span>
                      <a href="#" className="text-body fw-normal">
                        پاسخ
                      </a>
                    </div>
                    <p>
                      در نهایت این مرگ است که باید پیروز شود، زیرا از هنگام تولد
                      بخشی از سرنوشت ما شده و فقط مدت کوتاهی پیش از بلعیدن طعمه
                      اش، با آن بازی می کند.
                    </p>
                  </div>
                </div>

                <div className="my-4 d-flex ps-2 ps-md-3">
                  <img
                    className="avatar avatar-md rounded-circle float-start me-3"
                    src="assets/images/avatar/03.jpg"
                    alt="avatar"
                  />
                  <div>
                    <div className="mb-2">
                      <h5 className="m-0">مهرداد نوری</h5>
                      <span className="me-3 small">
                        21 خرداد، 1400 در 3:00 بعد از ظهر
                      </span>
                      <a href="#" className="text-body fw-normal">
                        پاسخ
                      </a>
                    </div>
                    <p>
                      همان‌ طور که تا آنجا که ممکن است طولانی‌ تر در یک حباب
                      صابون می‌ دمیم تا بزرگتر شود!
                    </p>
                  </div>
                </div>

                <div className="my-4 d-flex">
                  <img
                    className="avatar avatar-md rounded-circle float-start me-3"
                    src="assets/images/avatar/04.jpg"
                    alt="avatar"
                  />
                  <div>
                    <div className="mb-2">
                      <h5 className="m-0">رضا کریمی</h5>
                      <span className="me-3 small">
                        21 خرداد، 1400 در 3:00 بعد از ظهر{" "}
                      </span>
                      <a href="#" className="text-body fw-normal">
                        پاسخ
                      </a>
                    </div>
                    <p>
                      ما تا آنجا که ممکن است، با علاقه فراوان و دلواپسی زیاد به
                      زندگی ادامه می دهیم، همان‌ طور که تا آنجا که ممکن است
                      طولانی‌ تر در یک حباب صابون می‌ دمیم تا بزرگتر شود، گر چه
                      با قطعیتی تمام می‌ دانیم که خواهد ترکید.
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <h3>ثبت دیدگاه</h3>
                <small>
                  آدرس ایمیل شما منتشر نخواهد شد. فیلدهای الزامی علامت گذاری شده
                  اند *
                </small>
                <form className="row g-3 mt-2">
                  <div className="col-md-6">
                    <label className="form-label">نام *</label>
                    <input
                      type="text"
                      className="form-control"
                      aria-label="First name"
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">ایمیل *</label>
                    <input type="email" className="form-control" />
                  </div>

                  <div className="col-md-12">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        برای دفعه بعد که نظر دادم نام و ایمیل من را در این
                        مرورگر ذخیره شود.
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <label className="form-label">متن دیدگاه *</label>
                    <textarea className="form-control" rows={3}></textarea>
                  </div>
                  <div className="col-12">
                    <button type="submit" className="btn btn-primary">
                      ثبت
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div className="col-lg-3">
              <div data-sticky data-margin-top="80" data-sticky-for="991">
                <div>
                  <h3 className="mb-3">اخبار مشابه </h3>
                  <div className="tiny-slider dots-creative mt-3 mb-5">
                    <div
                      className="tiny-slider-inner"
                      data-autoplay="false"
                      data-hoverpause="true"
                      data-gutter="0"
                      data-arrow="false"
                      data-dots="true"
                      data-items="1"
                    >
                      <div className="card">
                        <div className="position-relative">
                          <img
                            className="card-img"
                            src="assets/images/blog/4by3/07.jpg"
                            alt="Card image"
                          />
                          <div className="card-img-overlay d-flex align-items-start flex-column p-3">
                            <div className="w-100 mb-auto d-flex justify-content-end">
                              <div className="text-end ms-auto">
                                <div
                                  className="icon-md bg-white bg-opacity-10 bg-blur text-white fw-bold rounded-circle"
                                  title="8.5 rating"
                                >
                                  8.5
                                </div>
                              </div>
                            </div>

                            <div className="w-100 mt-auto">
                              <a href="#" className="badge text-bg-info mb-2">
                                <i className="fas fa-circle me-2 small fw-bold"></i>
                                سیاست
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="card-body p-0 pt-3">
                          <h5 className="card-title">
                            <a
                              href="post-single-5.html"
                              className="btn-link text-reset"
                            >
                              طرح مجلس برای گرفتن مالیات از سفته بازها
                            </a>
                          </h5>
                        </div>
                      </div>

                      <div className="card">
                        <div className="position-relative">
                          <img
                            className="card-img"
                            src="assets/images/blog/4by3/08.jpg"
                            alt="Card image"
                          />
                          <div className="card-img-overlay d-flex align-items-start flex-column p-3">
                            <div className="w-100 mt-auto">
                              <a href="#" className="badge text-bg-danger mb-2">
                                <i className="fas fa-circle me-2 small fw-bold"></i>
                                ورزش
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="card-body p-0 pt-3">
                          <h5 className="card-title">
                            <a
                              href="post-single-5.html"
                              className="btn-link text-reset"
                            >
                              آمار فرزندان حاصل از روش‌های کمک‌ باروری
                            </a>
                          </h5>
                        </div>
                      </div>

                      <div className="card">
                        <div className="position-relative">
                          <img
                            className="card-img"
                            src="assets/images/blog/4by3/09.jpg"
                            alt="Card image"
                          />
                          <div className="card-img-overlay d-flex align-items-start flex-column p-3">
                            <div className="w-100 mt-auto">
                              <a
                                href="#"
                                className="badge text-bg-success mb-2"
                              >
                                <i className="fas fa-circle me-2 small fw-bold"></i>
                                اقتصاد
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="card-body p-0 pt-3">
                          <h5 className="card-title">
                            <a
                              href="post-single-5.html"
                              className="btn-link text-reset"
                            >
                              سال 2022 رویایی و فوق العاده برای طارمی
                            </a>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-4">
                  <a href="#" className="d-block card-img-flash">
                    <img src="assets/images/adv.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Post;
