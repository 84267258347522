import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { useSidebar } from "../../Hooks/useSidebar";
import { useAuth } from "../../Hooks/useAuthentication";
import { AuthContext } from "../../Context";

const Header = () => {
  const {token, setToken} = useContext(AuthContext)
  const { handleLogout } = useAuth(setToken);
  const { menuItems } = useSidebar();

  // ================================================================================

  return (
    <header className="navbar-light w-100 navbar-sticky header-static border-bottom navbar-dashboard">
      <nav className="navbar navbar-expand-xl">
        <div className="container">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <Link className="navbar-brand ms-3 me-auto" to={{ pathname: "/" }}>
            <img
              className="navbar-brand-item light-mode-item"
              src="/imgs/logo.svg"
              alt="logo"
            />
          </Link>

          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav navbar-nav-scroll mx-auto">
              {menuItems.map((item) => (
                <li className="nav-item" key={item.id}>
                  <Link className="nav-link" to={{ pathname: item.path }}>
                    <i className={`bi bi-${item.icon} me-1`}></i>
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div className="nav flex-nowrap align-items-center">
            {token ? (
              <div className="nav-item ms-2 ms-md-3 dropdown">
                <Link
                  className="btn btn-primary-soft btn-round mb-0 d-flex justify-content-center align-items-center"
                  to={{ pathname: "/cart" }}
                >
                  <i className="bi-cart d-flex"></i>
                </Link>
              </div>
            ) : null}

            <div className="nav-item ms-2 dropdown">
              {token ? (
                <div
                  className="avatar avatar-sm p-0"
                  id="profileDropdown"
                  role="button"
                  data-bs-auto-close="outside"
                  data-bs-display="static"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    className="avatar-img rounded-circle"
                    src="/imgs/avatar.jpg"
                    alt="avatar"
                  />
                </div>
              ) : (
                <Link
                  className="btn btn-primary-soft btn-round mb-0 d-flex justify-content-center align-items-center"
                  to={{ pathname: "/enter-phone" }}
                >
                  <i className="bi-person fs-5 d-flex"></i>
                </Link>
              )}

              <ul
                className="dropdown-menu dropdown-animation dropdown-menu-end shadow pt-3"
                aria-labelledby="profileDropdown"
              >
                <li className="px-3">
                  <div className="d-flex align-items-center">
                    <div className="avatar me-3">
                      <img
                        className="avatar-img rounded-circle shadow"
                        src="/imgs/avatar.jpg"
                        alt="avatar"
                      />
                    </div>
                    <div>
                      <Link
                        className="h6 mt-2 mt-sm-0"
                        to={{ pathname: "/profile" }}
                      >
                        علیرضا مرادی
                      </Link>
                      <p className="small m-0">example@gmail.com</p>
                    </div>
                  </div>
                  <hr />
                </li>

                <li>
                  <Link className="dropdown-item" to={{ pathname: "/profile" }}>
                    <i className="bi bi-person fa-fw me-2"></i>ویرایش
                  </Link>
                </li>
                <li>
                  <div onClick={handleLogout} className="dropdown-item">
                    <i className="bi bi-power fa-fw me-2"></i>خروج
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
