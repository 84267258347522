import { LoadingContext } from "../Context";
import React, { Fragment, useContext, useEffect, useState } from "react";

const Event = () => {
  const { setLoading } = useContext(LoadingContext);

  // ===============================================================

  const [event, setEvent] = useState();

  // ===============================================================

  useEffect(() => {
    setLoading(true);
  }, []);

  // ===============================================================

  return (
    <Fragment>
      <section>
        <div className="container">
          <div className="row g-4 g-lg-0 justify-content-between">
            <div className="col-lg-5">
              <div className="row g-2">
                <div className="col-12">
                  <div className="bg-light rounded-2 glightbox-bg p-4 position-relative">
                    <a
                      href="assets/images/shop/11.png"
                      className="stretched-link cursor-zoom"
                      data-glightbox
                      data-gallery="image-popup"
                    >
                      <img src="/imgs/shop/11.png" alt="" />
                    </a>
                  </div>
                </div>
                <div className="col-6">
                  <div className="bg-light rounded-2 glightbox-bg p-4 position-relative">
                    <a
                      href="assets/images/shop/13.png"
                      className="stretched-link cursor-zoom"
                      data-glightbox
                      data-gallery="image-popup"
                    >
                      <img src="/imgs/shop/13.png" alt="" />
                    </a>
                  </div>
                </div>
                <div className="col-6">
                  <div className="bg-light rounded-2 glightbox-bg p-4 position-relative">
                    <a
                      href="/imgs/shop/14.png"
                      className="stretched-link cursor-zoom"
                      data-glightbox
                      data-gallery="image-popup"
                    >
                      <img src="/imgs/shop/14.png" alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <h1>هواپیما اسباب بازی کنترلی</h1>
              <p className="mb-4">
                چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم
                است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با
                هدف بهبود ابزارهای کاربردی می باشد.
              </p>

              <ul className="list-inline mb-0">
                <li className="list-inline-item me-0">
                  <i className="fas fa-star text-warning"></i>
                </li>
                <li className="list-inline-item me-0">
                  <i className="fas fa-star text-warning"></i>
                </li>
                <li className="list-inline-item me-0">
                  <i className="fas fa-star text-warning"></i>
                </li>
                <li className="list-inline-item me-0">
                  <i className="fas fa-star text-warning"></i>
                </li>
                <li className="list-inline-item me-0">
                  <i className="fas fa-star-half-alt text-warning"></i>
                </li>
                <li className="list-inline-item me-0 h6">4.5/5.0</li>
              </ul>

              <div className="bg-light p-3 rounded-2 mb-4">
                <div className="row g-2">
                  <div className="col-sm-6">
                    <ul className="list-group list-group-borderless">
                      <li className="list-group-item py-0">
                        <span>وضعیت:</span>
                        <span className="h6 mb-0">جدید</span>
                      </li>
                      <li className="list-group-item pb-0">
                        <span>دسته بندی:</span>
                        <span className="h6 mb-0">
                          <a href="shop-grid.html">لوازم دیجیتال</a>
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div className="col-sm-6">
                    <ul className="list-group list-group-borderless">
                      <li className="list-group-item py-0">
                        <span>موجود در انبار:</span>
                        <span className="h6 mb-0">15</span>
                      </li>
                      <li className="list-group-item pb-0">
                        <span>وزن:</span>
                        <span className="h6 mb-0">2.2Kg</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="mb-4">
                <span>گالری تصاویر:</span>
                <ul className="list-inline mt-2">
                  <li className="list-inline-item">
                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id="option1"
                      checked
                    />
                    <label
                      className="btn btn-primary-soft-check"
                      htmlFor="option1"
                    >
                      <img
                        src="assets/images/shop/11.png"
                        className="w-60"
                        alt=""
                      />
                    </label>
                  </li>

                  <li className="list-inline-item">
                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id="option2"
                    />
                    <label
                      className="btn btn-primary-soft-check"
                      htmlFor="option2"
                    >
                      <img
                        src="assets/images/shop/12.png"
                        className="w-60"
                        alt=""
                      />
                    </label>
                  </li>
                </ul>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <h6 className="mb-0">جمع کل:</h6>
                  <h4 className="text-success">598000 تومان</h4>
                </div>

                <div className="col-md-2 pe-md-0 mb-2">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                  >
                    <option value="1">01</option>
                    <option value="2">02</option>
                    <option value="3">03</option>
                  </select>
                </div>

                <div className="col-md-6">
                  <a href="#" className="btn btn-primary mb-0 w-100">
                    <i className="bi bi-cart2 me-2"></i>افزودن به سبد
                  </a>
                  <p className="mb-0 mt-2 text-end small">
                    <i className="bi bi-question-circle-fill text-primary me-2"></i>
                    پشتیبانی؟{" "}
                    <a href="#" className="mb-0">
                      ارسال پیام
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-0 pt-lg-5">
        <div className="container">
          <div className="row g-4 justify-content-between">
            <div className="col-lg-7">
              <h5>توضیحات</h5>
              <p>
                متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای
                شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود
                ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته،
                حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم
                افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان
                خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد.
              </p>
              <p>
                <b>زمان پردازش:</b> چاپگرها و متون بلکه روزنامه و مجله در ستون و
                سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و
                کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد.
              </p>
              <p>
                <b>زمان ارسال:</b> با نرم افزارها شناخت بیشتری را برای طراحان
                رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی
                ایجاد کرد.
              </p>
              <p>
                ارائه راهکارها و شرایط سخت تایپ به پایان رسد وزمان مورد نیاز
                شامل حروفچینی دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل دنیای
                موجود طراحی اساسا مورد استفاده قرار گیرد.
              </p>
            </div>

            <div className="col-lg-4">
              <h5>فروشنده</h5>
              <div className="d-flex align-items-center position-relative">
                <div className="avatar avatar-sm">
                  <img
                    className="avatar-img rounded-circle"
                    src="assets/images/avatar/01.jpg"
                    alt="avatar"
                  />
                </div>
                <div className="ms-3">
                  <h6 className="mb-0">
                    با{" "}
                    <a href="#" className="stretched-link text-reset btn-link">
                      نازنین مرادی
                    </a>
                  </h6>
                </div>
              </div>

              <div className="hstack gap-3 flex-wrap mt-2 mb-4">
                <p className="mb-0">
                  <i className="bi bi-star-fill text-warning"></i> 5.0{" "}
                  <span>رضایت از کالا</span>
                </p>
                <p className="mb-0">
                  <i className="bi bi-patch-check-fill text-primary"></i> 99%{" "}
                  <span>پیشنهاد خرید</span>
                </p>
              </div>

              <h5>اطلاعات حمل و نقل</h5>
              <ul className="list-group list-group-borderless">
                <li className="list-group-item py-0">
                  <span>حمل و نقل:</span>
                  <span className="h6 mb-0">
                    حمل و نقل بین المللی طولانی مدت رایگان
                  </span>
                </li>
                <li className="list-group-item pb-0">
                  <span>تحویل:</span>
                  <span className="h6 mb-0">ارسال از 1 روز کاری دیگر</span>
                </li>
                <li className="list-group-item pb-0">
                  <span>پرداخت:</span>
                  <img
                    src="assets/images/icon/visa.svg"
                    className="w-40 me-2"
                    alt=""
                  />
                  <img
                    src="assets/images/icon/mastercard.svg"
                    className="w-40"
                    alt=""
                  />
                </li>
                <li className="list-group-item pb-0">
                  <span>بازگشت:</span>
                  <span className="h6 mb-0">ضمانت مرجوعی کالا تا یک هفته</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-0">
        <div className="container">
          <div className="mb-2">
            <h2 className="mb-0">امتیاز و دیدگاه ها</h2>
          </div>

          <div className="row g-4 justify-content-between">
            <div className="col-md-4">
              <div className="bg-primary p-4 rounded-2 mb-4">
                <div className="text-center text-white">
                  <h2 className="mb-0 text-white">4.5</h2>
                  <p className="mb-2">۲۳۶ دیدگاه</p>

                  <ul className="list-inline mb-0">
                    <li className="list-inline-item me-0">
                      <i className="fas fa-star text-warning"></i>
                    </li>
                    <li className="list-inline-item me-0">
                      <i className="fas fa-star text-warning"></i>
                    </li>
                    <li className="list-inline-item me-0">
                      <i className="fas fa-star text-warning"></i>
                    </li>
                    <li className="list-inline-item me-0">
                      <i className="fas fa-star text-warning"></i>
                    </li>
                    <li className="list-inline-item me-0">
                      <i className="fas fa-star-half-alt text-warning"></i>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="row g-2 align-items-center">
                <div className="col-12">
                  <h6 className="mb-0">امتیاز کلی</h6>
                </div>

                <div className="col-9 col-sm-10">
                  <div className="progress progress-sm bg-warning bg-opacity-15">
                    <div
                      className="progress-bar bg-warning"
                      role="progressbar"
                    ></div>
                  </div>
                </div>

                <div className="col-3 col-sm-2">
                  <span className="ms-auto h6 fw-light mb-0">85%</span>
                </div>

                <div className="col-9 col-sm-10">
                  <div className="progress progress-sm bg-warning bg-opacity-15">
                    <div
                      className="progress-bar bg-warning"
                      role="progressbar"
                    ></div>
                  </div>
                </div>

                <div className="col-3 col-sm-2">
                  <span className="ms-auto h6 fw-light mb-0">75%</span>
                </div>

                <div className="col-9 col-sm-10">
                  <div className="progress progress-sm bg-warning bg-opacity-15">
                    <div
                      className="progress-bar bg-warning w-60"
                      role="progressbar"
                    ></div>
                  </div>
                </div>

                <div className="col-3 col-sm-2">
                  <span className="ms-auto h6 fw-light mb-0">60%</span>
                </div>

                <div className="col-9 col-sm-10">
                  <div className="progress progress-sm bg-warning bg-opacity-15">
                    <div
                      className="progress-bar bg-warning"
                      role="progressbar"
                      style={{ width: "35%" }}
                    ></div>
                  </div>
                </div>

                <div className="col-3 col-sm-2">
                  <span className="ms-auto h6 fw-light mb-0">35%</span>
                </div>

                <div className="col-9 col-sm-10">
                  <div className="progress progress-sm bg-warning bg-opacity-15">
                    <div
                      className="progress-bar bg-warning w-20"
                      role="progressbar"
                    ></div>
                  </div>
                </div>

                <div className="col-3 col-sm-2">
                  <span className="ms-auto h6 fw-light mb-0">15%</span>
                </div>
              </div>
            </div>

            <div className="col-md-7">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="mb-0">مرتب سازی</h5>

                <div className="col-xl-3">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                  >
                    <option value="1">مرتبط ترین</option>
                    <option value="2">پربازدید ترین</option>
                    <option value="3">پیشنهاد خریداران</option>
                  </select>
                </div>
              </div>

              <hr className="my-4" />

              <div className="d-flex">
                <img
                  className="avatar avatar-md rounded-circle float-start me-3"
                  src="assets/images/avatar/02.jpg"
                  alt="avatar"
                />
                <div>
                  <div className="d-flex justify-content-between mb-2">
                    <div>
                      <h5 className="m-0">علیرضا اکبری</h5>
                      <span className="me-3 small">
                        11 بهمن، 1400، در 2:00 بعد از ظهر{" "}
                      </span>
                    </div>

                    <ul className="list-inline mb-0">
                      <li className="list-inline-item small me-0">
                        <i className="fas fa-star text-warning"></i>
                      </li>
                      <li className="list-inline-item small me-0">
                        <i className="fas fa-star text-warning"></i>
                      </li>
                      <li className="list-inline-item small me-0">
                        <i className="fas fa-star text-warning"></i>
                      </li>
                      <li className="list-inline-item small me-0">
                        <i className="fas fa-star text-warning"></i>
                      </li>
                      <li className="list-inline-item small me-0">
                        <i className="fas fa-star-half-alt text-warning"></i>
                      </li>
                    </ul>
                  </div>
                  <p>
                    زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی
                    سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار
                    گیرد.
                  </p>

                  <span>مفید بود؟</span>
                  <div
                    className="btn-group ms-md-2"
                    role="group"
                    aria-label="Basic radio toggle button group"
                  >
                    <input
                      type="radio"
                      className="btn-check"
                      name="btnradio"
                      id="btnradio3"
                    />
                    <label
                      className="btn btn-outline-light btn-sm mb-0"
                      htmlFor="btnradio3"
                    >
                      <i className="far fa-thumbs-up me-1"></i> بله
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      name="btnradio"
                      id="btnradio4"
                    />
                    <label
                      className="btn btn-outline-light btn-sm mb-0"
                      htmlFor="btnradio4"
                    >
                      {" "}
                      خیر <i className="far fa-thumbs-down ms-1"></i>
                    </label>
                  </div>
                </div>
              </div>

              <hr className="my-4" />

              <div className="d-flex">
                <img
                  className="avatar avatar-md rounded-circle float-start me-3"
                  src="assets/images/avatar/04.jpg"
                  alt="avatar"
                />
                <div>
                  <div className="d-flex justify-content-between mb-2">
                    <div>
                      <h5 className="m-0">رامین موحد</h5>
                      <span className="me-3 small">
                        11 بهمن، 1400، در 2:00 بعد از ظهر{" "}
                      </span>
                    </div>

                    <ul className="list-inline mb-0">
                      <li className="list-inline-item small me-0">
                        <i className="fas fa-star text-warning"></i>
                      </li>
                      <li className="list-inline-item small me-0">
                        <i className="fas fa-star text-warning"></i>
                      </li>
                      <li className="list-inline-item small me-0">
                        <i className="fas fa-star text-warning"></i>
                      </li>
                      <li className="list-inline-item small me-0">
                        <i className="fas fa-star text-warning"></i>
                      </li>
                      <li className="list-inline-item small me-0">
                        <i className="fas fa-star-half-alt text-warning"></i>
                      </li>
                    </ul>
                  </div>
                  <p>
                    با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی
                    الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد.
                  </p>

                  <span>مفید بود؟</span>
                  <div
                    className="btn-group ms-md-2"
                    role="group"
                    aria-label="Basic radio toggle button group"
                  >
                    <input
                      type="radio"
                      className="btn-check"
                      name="btnradio"
                      id="btnradio5"
                    />
                    <label
                      className="btn btn-outline-light btn-sm mb-0"
                      htmlFor="btnradio5"
                    >
                      <i className="far fa-thumbs-up me-1"></i> بله
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      name="btnradio"
                      id="btnradio6"
                    />
                    <label
                      className="btn btn-outline-light btn-sm mb-0"
                      htmlFor="btnradio6"
                    >
                      {" "}
                      نه <i className="far fa-thumbs-down ms-1"></i>
                    </label>
                  </div>
                </div>
              </div>

              <div className="mt-4 text-end">
                <a
                  className="btn btn-primary-soft mb-0"
                  data-bs-toggle="collapse"
                  href="#collapseExample"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  ثبت دیدگاه
                </a>
              </div>

              <div className="collapse" id="collapseExample">
                <div className="card card-body">
                  <form>
                    <select
                      className="form-select mb-3"
                      aria-label="Default select example"
                    >
                      <option value="1">★★★★★ (5/5)</option>
                      <option value="2">★★★★☆ (4/5)</option>
                      <option value="3">★★★☆☆ (3/5)</option>
                      <option value="3">★★☆☆☆ (2/5)</option>
                      <option value="3">★☆☆☆☆ (1/5)</option>
                    </select>

                    <textarea
                      className="form-control mb-3"
                      id="exampleFormControlTextarea1"
                      placeholder="Your review"
                      rows={3}
                    ></textarea>

                    <button type="submit" className="btn btn-primary mb-0">
                      ثبت نظر <i className="bi fa-fw bi-arrow-right ms-2"></i>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-0 pt-md-5">
        <div className="container">
          <div className="mb-4">
            <h2 className="mb-0">کالاهای مشابه</h2>
          </div>

          <div className="row g-4">
            <div className="col-md-12">
              <div className="tiny-slider arrow-hover arrow-dark arrow-blur arrow-round">
                <div
                  className="tiny-slider-inner"
                  data-autoplay="false"
                  data-hoverpause="true"
                  data-gutter="24"
                  data-edge="2"
                  data-arrow="true"
                  data-dots="false"
                  data-items-xl="4"
                  data-items-lg="3"
                  data-items-md="2"
                  data-items-xs="1"
                >
                  <div>
                    <div className="card border p-3 h-100">
                      <div className="position-relative">
                        <a
                          href="shop-detail.html"
                          className="position-relative z-index-9"
                        >
                          <img
                            className="card-img"
                            src="assets/images/shop/02.png"
                            alt=""
                          />
                        </a>

                        <div className="card-img-overlay p-0">
                          <span className="badge text-bg-success">جدید</span>
                        </div>
                      </div>

                      <div className="card-body text-center p-3 px-0">
                        <div className="d-flex justify-content-center mb-2">
                          <ul className="list-inline mb-0">
                            <li className="list-inline-item me-0 small">
                              <i className="fas fa-star text-warning"></i>
                            </li>
                            <li className="list-inline-item me-0 small">
                              <i className="fas fa-star text-warning"></i>
                            </li>
                            <li className="list-inline-item me-0 small">
                              <i className="fas fa-star text-warning"></i>
                            </li>
                            <li className="list-inline-item me-0 small">
                              <i className="fas fa-star text-warning"></i>
                            </li>
                            <li className="list-inline-item me-0 small">
                              <i className="fas fa-star-half-alt text-warning"></i>
                            </li>
                          </ul>
                        </div>

                        <h5 className="card-title">
                          <a href="shop-detail.html">
                            کوله پشتی ورزشی مدل کلاسیک
                          </a>
                        </h5>
                        <h6 className="mb-0 text-success">103000 تومان</h6>
                      </div>

                      <div className="card-footer text-center p-0">
                        <a
                          href="#"
                          className="btn btn-sm btn-primary-soft mb-0"
                        >
                          <i className="bi bi-cart me-2"></i>افزودن به سبد
                        </a>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="card border p-3 h-100">
                      <a href="shop-detail.html">
                        <img
                          className="card-img"
                          src="assets/images/shop/04.png"
                          alt=""
                        />
                      </a>

                      <div className="card-body text-center p-3 px-0">
                        <div className="d-flex justify-content-center mb-2">
                          <ul className="list-inline mb-0">
                            <li className="list-inline-item me-0 small">
                              <i className="fas fa-star text-warning"></i>
                            </li>
                            <li className="list-inline-item me-0 small">
                              <i className="fas fa-star text-warning"></i>
                            </li>
                            <li className="list-inline-item me-0 small">
                              <i className="fas fa-star text-warning"></i>
                            </li>
                            <li className="list-inline-item me-0 small">
                              <i className="fas fa-star text-warning"></i>
                            </li>
                            <li className="list-inline-item me-0 small">
                              <i className="fas fa-star-half-alt text-warning"></i>
                            </li>
                          </ul>
                        </div>

                        <h5 className="card-title">
                          <a href="shop-detail.html">کاپشن مردانه چرم کن</a>
                        </h5>
                        <h6 className="mb-0 text-success">98000 تومان</h6>
                      </div>

                      <div className="card-footer text-center p-0">
                        <a
                          href="#"
                          className="btn btn-sm btn-primary-soft mb-0"
                        >
                          <i className="bi bi-cart me-2"></i>افزودن به سبد
                        </a>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="card border p-3 h-100">
                      <a href="shop-detail.html">
                        <img
                          className="card-img"
                          src="assets/images/shop/08.png"
                          alt=""
                        />
                      </a>

                      <div className="card-body text-center p-3 px-0">
                        <div className="d-flex justify-content-center mb-2">
                          <ul className="list-inline mb-0">
                            <li className="list-inline-item me-0 small">
                              <i className="fas fa-star text-warning"></i>
                            </li>
                            <li className="list-inline-item me-0 small">
                              <i className="fas fa-star text-warning"></i>
                            </li>
                            <li className="list-inline-item me-0 small">
                              <i className="fas fa-star text-warning"></i>
                            </li>
                            <li className="list-inline-item me-0 small">
                              <i className="fas fa-star text-warning"></i>
                            </li>
                            <li className="list-inline-item me-0 small">
                              <i className="fas fa-star-half-alt text-warning"></i>
                            </li>
                          </ul>
                        </div>

                        <h5 className="card-title">
                          <a href="shop-detail.html">ست 6 تکه لباس ورزشی</a>
                        </h5>
                        <h6 className="mb-0 text-success">105000 تومان</h6>
                      </div>

                      <div className="card-footer text-center p-0">
                        <a
                          href="#"
                          className="btn btn-sm btn-primary-soft mb-0"
                        >
                          <i className="bi bi-cart me-2"></i>افزودن به سبد
                        </a>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="card border p-3 h-100">
                      <a href="shop-detail.html">
                        <img
                          className="card-img"
                          src="/imgs/shop/06.png"
                          alt=""
                        />
                      </a>

                      <div className="card-body text-center p-3 px-0">
                        <div className="d-flex justify-content-center mb-2">
                          <ul className="list-inline mb-0">
                            <li className="list-inline-item me-0 small">
                              <i className="fas fa-star text-warning"></i>
                            </li>
                            <li className="list-inline-item me-0 small">
                              <i className="fas fa-star text-warning"></i>
                            </li>
                            <li className="list-inline-item me-0 small">
                              <i className="fas fa-star text-warning"></i>
                            </li>
                            <li className="list-inline-item me-0 small">
                              <i className="fas fa-star text-warning"></i>
                            </li>
                            <li className="list-inline-item me-0 small">
                              <i className="fas fa-star-half-alt text-warning"></i>
                            </li>
                          </ul>
                        </div>

                        <h5 className="card-title">
                          <a href="shop-detail.html">بوت زنانه چرم آرا</a>
                        </h5>
                        <div className="d-flex align-items-center justify-content-center">
                          <h6 className="text-success mb-0 me-2">
                            75000 تومان
                          </h6>
                          <small className="text-decoration-line-through">
                            95000 تومان
                          </small>
                        </div>
                      </div>

                      <div className="card-footer text-center p-0">
                        <a
                          href="#"
                          className="btn btn-sm btn-primary-soft mb-0"
                        >
                          <i className="bi bi-cart me-2"></i>افزودن به سبد
                        </a>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="card border p-3 h-100">
                      <a href="shop-detail.html">
                        <img
                          className="card-img"
                          src="assets/images/shop/07.png"
                          alt=""
                        />
                      </a>

                      <div className="card-body text-center p-3 px-0">
                        <div className="d-flex justify-content-center mb-2">
                          <ul className="list-inline mb-0">
                            <li className="list-inline-item me-0 small">
                              <i className="fas fa-star text-warning"></i>
                            </li>
                            <li className="list-inline-item me-0 small">
                              <i className="fas fa-star text-warning"></i>
                            </li>
                            <li className="list-inline-item me-0 small">
                              <i className="fas fa-star text-warning"></i>
                            </li>
                            <li className="list-inline-item me-0 small">
                              <i className="fas fa-star text-warning"></i>
                            </li>
                            <li className="list-inline-item me-0 small">
                              <i className="fas fa-star-half-alt text-warning"></i>
                            </li>
                          </ul>
                        </div>

                        <h5 className="card-title">
                          <a href="shop-detail.html">
                            اجاق گاز پیکنیکی مدل مخزن
                          </a>
                        </h5>
                        <h6 className="mb-0 text-success">81000 تومان</h6>
                      </div>

                      <div className="card-footer text-center p-0">
                        <a
                          href="#"
                          className="btn btn-sm btn-primary-soft mb-0"
                        >
                          <i className="bi bi-cart me-2"></i>افزودن به سبد
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Event;