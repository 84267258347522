import React from "react";

interface ButtonProps {
  text?: string;
  disabled?: boolean;
  btnClass?: string
  children?: JSX.Element
  isLoading?: boolean
  onClick?: (e: any) => void
}

const Button = (props: ButtonProps) => {
  return (
    <button
      type={props.onClick ? "button" : "submit"}
      disabled={props.disabled || props.isLoading}
      style={{height: "48px"}}
      onClick={props.onClick}
      className={`btn d-flex justify-content-center align-items-center position-relative overflow-hidden ${props.btnClass}`}
      
    >
      {props.isLoading ? <span className="btn-loader"></span> : props.text ?? props.children}
    </button>
  );
};

export default Button;
