import { Link } from "react-router-dom";
import { MainContext } from "../../Context";
import React, { useContext } from "react";

const Footer = () => {
  const { setting } = useContext(MainContext);

  return (
    <footer className="pb-0 w-100">
      <div className="container">
        <hr className="mt-0" />

        <div className="row pt-5">
          <div className="col-md-6 col-lg-4 mb-4">
            <img className="light-mode-item" src="/imgs/logo.svg" alt="logo" />
            <p className="mt-3">این جا یک متن قرار میگیرد</p>
          </div>

          <div className="col-md-6 col-lg-3 mb-4">
            <h5 className="mb-4">لینک های مفید</h5>
            <div className="row">
              <div className="col-6">
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <Link className="nav-link" to="/">
                      خانه
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link pt-0" to="/about-us">
                      درباره ما
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/contact-us">
                      تماس با ما
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/rules">
                      احکام
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/profile">
                      حساب کاربری
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-lg-2 mb-4">
            <h5 className="mb-4">شبکه های اجتماعی</h5>
            <ul className="nav flex-column">
              <li className="nav-item">
                <a
                  target="_blank"
                  className="nav-link pt-0"
                  href={setting.instagramLink}
                >
                  <i className="fab fa-instagram fa-fw me-2 text-instagram"></i>
                  Instagram
                </a>
              </li>
              <li className="nav-item">
                <a
                  target="_blank"
                  className="nav-link"
                  href={setting.telegramLink}
                >
                  <i className="fab fa-telegram fa-fw me-2 text-info"></i>
                  Telegram
                </a>
              </li>
              <li className="nav-item">
                <a
                  target="_blank"
                  className="nav-link"
                  href={setting.whatsappLink}
                >
                  <i className="fab fa-whatsapp fa-fw me-2 text-success"></i>
                  Whatsapp
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
