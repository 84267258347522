import Footer from "../../Components/Common/Footer";
import Header from "../../Components/Common/Header";

import React, { Fragment } from "react";

const MainLayout = ({ children }: any) => {
  return (
    <Fragment>
      <Header />
      <main className="flex-grow-1 w-100">{children}</main>
      <Footer />
    </Fragment>
  );
};

export default MainLayout;
