import React, { FormEvent, useContext, useEffect, useState } from "react";
import Toast from "../Utils/Toast";
import { useLocation, useNavigate } from "react-router";
import { Button, Input } from "../Components/Base";
import {
  TransactionPostResendCode,
  TransactionPostVerifyPhone,
} from "../Services/Transactions";
import { AuthContext } from "../Context";

const VerifyPhone = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // ======================================================

  const { setToken } = useContext(AuthContext);

  // ======================================================

  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);

  // ======================================================

  const handleSubmitForm = (e: FormEvent) => {
    e.preventDefault();
    if (code) {
      setLoading(true);
      TransactionPostVerifyPhone({ code, phoneNumber: location.state?.phone })
        .then(({ data: res }) => {
          if (res.transaction_status) {
            localStorage.setItem("Token", res.data!.token);
            setToken(res.data!.token);
            if (res.data!.personal_verification) {
              Toast("success", "لطفا ابتدا احراز هویت کنید.");
              navigate("/profile");
            } else {
              Toast("success", "با موفقیت وارد شدی");
              navigate("/");
            }
          } else {
            Toast("error", res.message);
          }
          setLoading(false);
        })
        .catch(() => setLoading(false));
    } else Toast("error", "لطفا کد رو وارد کن!");
  };

  const handleResendCode = () => {
    setLoading(true);
    TransactionPostResendCode({ phoneNumber: location.state?.phone })
      .then(({ data }) => {
        Toast(data.transaction_status ? "success" : "error", data.message);
      })
      .finally(() => setLoading(false));
  };

  // ======================================================

  useEffect(() => {
    if (!!!location.state?.phone) {
      navigate("/enter-phone");
    }
  }, []);

  // ======================================================
  return (
    <div
      className="container my-5 bg-primary-soft rounded-3"
      style={{ background: "rgba(33, 99, 232, 0.1)" }}
    >
      <div className="row">
        <div className="col-md-10 col-lg-8 col-xl-6 mx-auto">
          <div className="p-4 my-5 p-sm-5 rounded">
            <h2 className="text-center">تایید شماره موبایل</h2>

            <form className="mt-4" onSubmit={handleSubmitForm}>
              <div className="mb-2">
                <Input
                  placeholder="کد تایید ارسال شده را وارد کنید."
                  inputClass="text-center"
                  setValue={setCode}
                  value={code}
                  type="tel"
                  label="کد تایید"
                  inputAttrs={{ autoFocus: true, dir: "ltr" }}
                />
              </div>
              <div className="d-flex justify-content-between mb-3 align-items-center">
                <p
                  className="cursor-pointer"
                  onClick={() =>
                    navigate("/enter-phone", {
                      state: { phone: location.state?.phone },
                    })
                  }
                >
                  ویرایش شماره
                </p>
              </div>
              <div className="row align-items-center">
                <div className="col-12">
                  <Button
                    disabled={code.length != 5}
                    btnClass="btn-primary w-100"
                    isLoading={loading}
                  >
                    <span>تایید</span>
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyPhone;
