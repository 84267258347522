import { Pagination } from "../Components/Base";
import React, { useContext, useEffect, useState } from "react";
import { EventCategory, EventsListType } from "../Structures";
import { formatText, formatDate } from "../Utils";
import { LoadingContext } from "../Context";

const MyEvents = () => {
  const { setLoading } = useContext(LoadingContext);

  // ===========================================================================

  const [myEventsList, setMyEventsList] = useState<EventsListType[]>([]);

  // ===========================================================================

  useEffect(() => {
    setLoading(true);
  }, []);

  // ===========================================================================

  return ( 
    <div className="container" data-sticky-container>
      <div className="row gy-4">
        {myEventsList.map((event: EventsListType) => (
          <div className="col-sm-6">
            <div className="card">
              <div className="position-relative">
                <img
                  className="card-img"
                  src="/imgs/blog/4by3/01.jpg"
                  alt="Card image"
                />
                <div className="card-img-overlay d-flex align-items-start flex-column p-3">
                  <div className="w-100 mt-auto">
                    {event.categories.map((category: EventCategory) => (
                      <span
                        className="badge text-bg-warning me-1 mb-2"
                        key={category.id}
                      >
                        <i className="fas fa-circle me-2 small fw-bold"></i>
                        {category.title}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
              <div className="card-body px-0 pt-3">
                <h4 className="card-title">
                  <span className="btn-link text-reset fw-bold">
                    {event.title}
                  </span>
                </h4>
                <p className="card-text">{formatText(event.description, 40)}</p>

                <ul className="nav nav-divider align-items-center d-none d-sm-inline-block">
                  <li className="nav-item">تاریخ:‌ {formatDate(event.date)}</li>
                  <span className="fw-bolder mx-2">-</span>
                  <li className="nav-item">ساعت: {event.time}</li>
                </ul>
              </div>
            </div>
          </div>
        ))}

        <Pagination />
      </div>
    </div>
  );
};

export default MyEvents;
