import React, { useContext, useEffect, useState } from "react";

import { LoadingContext } from "../Context";

const Album = () => {
  const { setLoading } = useContext(LoadingContext);

  // ==============================================================

  const [assets, setAssets] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
  ]);

  // ==============================================================

  useEffect(() => {
    // setLoading(true);
  }, []);

  // ==============================================================

  return (
    <section className="position-relative pt-0">
      <div className="container">
        <div className="row filter-container overflow-hidden">
          {assets.map((asset, index) => (
            <div className="col-sm-6 col-lg-4 grid-item">
              <div className="card mb-4">
                <div className="card-fold position-relative">
                  <img
                    className="card-img"
                    src={`/imgs/blog/1by1/${index + 1}.jpg`}
                    alt="Card image"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Album;
