import ProfileLayout from "../Container/Layout/ProfileLayout";
import React from "react";

const Profile = () => {
  return (
    <ProfileLayout>
        <div className="col-12">
          <div className="card border mb-4">
            <div className="card-header border-bottom p-3">
              <h4 className="card-header-title mb-0">حساب کاربری</h4>
            </div>
            <div className="card-body">
              <div className="mb-3">
                <label className="form-label">نام کامل</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    value="مهدی"
                    placeholder="نام"
                  />
                  <input
                    type="text"
                    className="form-control"
                    value="علیزاده"
                    placeholder="نام خانوادگی"
                  />
                </div>
              </div>

              <div className="mb-3">
                <label className="form-label">نام کاربری</label>
                <div className="input-group">
                  <span className="input-group-text">gmail.com</span>
                  <input
                    type="text"
                    className="form-control"
                    value="example@752"
                  />
                </div>
              </div>

              <div className="mb-3">
                <label className="form-label">تصویر پروفایل</label>

                <div className="d-flex align-items-center">
                  <div className="position-relative me-3">
                    <div className="position-absolute top-0 end-0  z-index-9">
                      <a
                        className="btn btn-sm btn-light btn-round mb-0 mt-n1 me-n1"
                        href="#"
                      >
                        {" "}
                        <i className="bi bi-pencil"></i>{" "}
                      </a>
                    </div>

                    <div className="avatar avatar-xl">
                      <img
                        className="avatar-img rounded-circle border border-white border-3 shadow"
                        src="assets/images/avatar/03.jpg"
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="avatar-remove">
                    <button type="button" className="btn btn-light">
                      حذف
                    </button>
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <label className="form-label">عنوان شغلی</label>
                <input
                  className="form-control"
                  type="text"
                  value="روزنامه نگار Blogzine"
                />
              </div>

              <div className="mb-3">
                <label className="form-label">موقعیت مکانی</label>
                <input
                  className="form-control"
                  type="text"
                  value="خیابان شریعتی"
                />
              </div>

              <div className="mb-3">
                <label className="form-label">توضیحات</label>
                <textarea className="form-control" rows={3}>
                  من راهی برای دریافت پول برای سرگرمی مورد علاقه‌ام پیدا کرده‌ام
                  و این کار را در حالی انجام می‌دهم که رویای سفر به دور دنیا را
                  دنبال می‌کنم.
                </textarea>
                <div className="form-text">توضیحات مختصری برای پروفایل شما</div>
              </div>

              <div>
                <label className="form-label">تاریخ تولد</label>
                <input
                  type="text"
                  className="form-control flatpickr-input"
                  placeholder="DD/MM/YYYY"
                  value="12/10/1400"
                />
              </div>

              <div className="d-flex justify-content-end mt-4">
                <a href="#" className="btn text-secondary border-0 me-2">
                  لغو
                </a>
                <a href="#" className="btn btn-primary">
                  ذخیره
                </a>
              </div>
            </div>
          </div>
        </div>
    </ProfileLayout>
  );
};

export default Profile;
