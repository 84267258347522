import React, { type FC } from "react";
import { Route, Routes } from "react-router-dom";

import MainLayout from "./Layout/MainLayout";

import {
  Landing,
  AboutUs,
  Rules,
  Blog,
  Post,
  Gallery,
  Album,
  EnterPhone,
  VerifyPhone,
  ContactUs,
  NotFound,
  Events,
  Event,
  Coachs,
  Coach,
} from "../Pages";

const UnAuthenticated: FC = () => {
  return (
    <MainLayout>
      <Routes>
        <Route path="/enter-phone" element={<EnterPhone />} />
        <Route path="/verify-phone" element={<VerifyPhone />} />

        <Route path="/" element={<Landing />} />
        <Route path="/rules" element={<Rules />} />

        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id/" element={<Post />} />

        <Route path="/gallery" element={<Gallery />} />
        <Route path="/gallery/:id/" element={<Album />} />
        
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />

        <Route path="/events" element={<Events />} />
        <Route path="/events/:id/" element={<Event />} />

        <Route path="/coachs" element={<Coachs />} />
        <Route path="/coachs/:id/" element={<Coach />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </MainLayout>
  );
};

export default UnAuthenticated;
