interface MenuItem {
  id: number;
  icon: string;
  path: string;
  title: string;
}

interface useSidebarReturns {
  menuItems: MenuItem[];
}

export const useSidebar = (): useSidebarReturns => {
  const menuItems = [
    {
      id: 1,
      icon: "house-door",
      path: "/",
      title: "خانه",
    },
    {
      id: 2,
      icon: "newspaper",
      path: "/blog",
      title: "اخبار",
    },
    {
      id: 3,
      icon: "info-circle",
      path: "/about-us",
      title: "درباره ما",
    },
    {
      id: 4,
      icon: "telephone",
      path: "/contact-us",
      title: "تماس با ما",
    },
    {
      id: 5,
      icon: "image",
      path: "/gallery",
      title: "گالری",
    },
    {
      id: 6,
      icon: "calendar-event",
      path: "/events",
      title: "رویداد ها",
    },
    {
      id: 7,
      icon: "person",
      path: "/coachs",
      title: "اساتید",
    },
    {
      id: 8,
      icon: "hr",
      path: "/rules",
      title: "احکام",
    },
  ];

  return { menuItems };
};
