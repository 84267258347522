import { createContext, Dispatch, SetStateAction } from "react";

type LoadingContextType = {
    loading: boolean
    setLoading: Dispatch<SetStateAction<boolean>>
}

const LoadingContext = createContext<LoadingContextType>({
    loading: false,
    setLoading: () => {},
});

export default LoadingContext;
