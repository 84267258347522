import config from "./config.json";

import apiClient from "./apiClient";

import {
  TransactinPostEnterPhoneType,
  TransactinPostVerifyPhoneType,
  TransactinGetSettingType,
  TransactionMessagePostType,
  TransactionGetRulesType,
  TransactionGetBlogType,
  TransactionGetPostType,
  TransactionGetGalleryType,
  TransactionGetAlbumType,
  TransactionGetCoachsType,
  TransactionGetCoachType,
  TransactionGetEventsType,
  TransactionGetEventType,
  TransactionGetEventCategoriesType,
} from "../Structures";

// ============================================================================================

const _getAbsoluteUrl = (url: string) => {
  return `${config.serverUrl}/${url}`;
};

// #region ======================================== Login ====================================================

export const TransactinPostEnterPhone: TransactinPostEnterPhoneType = (
  params
) => {
  return apiClient.post(_getAbsoluteUrl("login/"), params);
};

export const TransactionPostResendCode: TransactinPostEnterPhoneType = (
  params
) => {
  return apiClient.post(_getAbsoluteUrl("login/"), params);
};

export const TransactionPostVerifyPhone: TransactinPostVerifyPhoneType = (
  params
) => {
  return apiClient.post(_getAbsoluteUrl("check-code/"), params);
};

// #endregion ============================================================================================

// #region =========================================== Setting =================================================

export const TransactionSettingGet: TransactinGetSettingType = () => {
  return apiClient.get(_getAbsoluteUrl("setting/"));
};

// #endregion ============================================================================================

// #region =========================================== User Message =================================================

export const TransactionMessagePost: TransactionMessagePostType = (params) => {
  return apiClient.post(_getAbsoluteUrl("user/message/"), params);
};

// #endregion ============================================================================================

// #region ======================================= Rules =====================================================

export const TransactionRulesGet: TransactionGetRulesType = () => {
  return apiClient.get(_getAbsoluteUrl("rules/"));
};

// #endregion ============================================================================================

// #region ======================================= Blog =====================================================

export const TransactionGetBlog: TransactionGetBlogType = () => {
  return apiClient.get(_getAbsoluteUrl("blog/"));
};

export const TransactionGetPost: TransactionGetPostType = (postId: string) => {
  return apiClient.get(_getAbsoluteUrl(`blog/${postId}/`));
};

// #endregion ============================================================================================

// #region ======================================= Gallery =====================================================

export const TransactionGetGallery: TransactionGetGalleryType = () => {
  return apiClient.get(_getAbsoluteUrl("gallery/"));
};

export const TransactionGetAlbum: TransactionGetAlbumType = (
  albumId: string
) => {
  return apiClient.get(_getAbsoluteUrl(`gallery/${albumId}/`));
};

// #endregion ============================================================================================

// #region ======================================= Coach =====================================================

export const TransactionGetCoachs: TransactionGetCoachsType = () => {
  return apiClient.get(_getAbsoluteUrl("coachs/"));
};

export const TransactionGetCoach: TransactionGetCoachType = (
  coachId: string
) => {
  return apiClient.get(_getAbsoluteUrl(`coachs/${coachId}/`));
};

// #endregion ============================================================================================

// #region ======================================= Event =====================================================

export const TransactionGetEvents: TransactionGetEventsType = () => {
  return apiClient.get(_getAbsoluteUrl("events/"));
};

export const TransactionGetEventCategories: TransactionGetEventCategoriesType =
  () => {
    return apiClient.get(_getAbsoluteUrl("events-category/"));
  };

export const TransactionGetEvent: TransactionGetEventType = (
  eventId: string
) => {
  return apiClient.get(_getAbsoluteUrl(`events/${eventId}/`));
};

// #endregion ============================================================================================
