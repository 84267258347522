import React, { FormEvent, Fragment, useState } from "react";
import Button from "../Components/Base/Button";
import Input from "../Components/Base/Input";
import Toast from "../Utils/Toast";
import { TransactionMessagePost } from "../Services/Transactions";
import { Link } from "react-router-dom";

const ContactUs = () => {
  const [fullname, setFullname] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  // =============================================================

  const isButtonActive = () =>
    fullname && phoneNumber.length == 11 && email && subject && message;

  // =============================================================

  const handleSubmitForm = (e: FormEvent) => {
    e.preventDefault();
    if (isButtonActive()) {
      setLoading(true);
      const data = {fullname, email, phoneNumber, subject, message}
      TransactionMessagePost(data)
        .then(({ data: res }) => {
          if (res.transaction_status) {
            setFullname("");
            setEmail("");
            setPhoneNumber("");
            setSubject("");
            setMessage("");
            Toast("success", "پیام شما با موفقیت ثبت شد.");
          } else {
            Toast("error", res.message);
          }
          setLoading(false);
        })
        .catch(() => setLoading(false));
    } else Toast("error", "لطفا اطلاعات را  به درستی وارد کنید.");
  };

  // =============================================================

  return (
    <Fragment>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-9 mx-auto text-center">
              <h1 className="display-5">تماس با ما</h1>

              <nav
                className="d-flex justify-content-center"
                aria-label="breadcrumb"
              >
                <ol className="breadcrumb breadcrumb-dots mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="bi bi-house me-1"></i> خانه
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">تماس با ما</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-4">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 mx-auto">
              <iframe
                className="w-100 h-300"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6484.772742287604!2d51.40598644446161!3d35.64285122120061!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f91fe3a29bad849%3A0xb4eacf9cb6647fc6!2z2YbYp9iy24wg2KLYqNin2K_YjCDYqtmH2LHYp9mG2Iwg2KfYs9iq2KfZhiDYqtmH2LHYp9mG2Iwg2KfbjNix2KfZhg!5e0!3m2!1sfa!2s!4v1673162969520!5m2!1sfa!2s"
                height="500"
                aria-hidden="false"
                loading="lazy"
              ></iframe>

              <div className="row mt-5">
                <div className="col-sm-6 mb-5 mb-sm-0">
                  <h3>تبلیغات / حمایت مالی</h3>
                  <p>جهت دریافت مشاوره برای تبلیغات با ما تماس بگیرید.</p>
                  <address>تهران، میدان هفت تیر، مجمتع صدف پلاک 82</address>
                  <p>
                    شماره تماس:{" "}
                    <a href="#" className="text-reset">
                      <u>469-537-2410</u>
                    </a>
                  </p>
                  <p>
                    خبر الکترونیکی:{" "}
                    <a href="#" className="text-reset">
                      <u>advertise@example.com</u>
                    </a>
                  </p>
                  <p>
                    ساعات کار: شنبه تا چهارشنبه
                    <br />
                    9:30 صبح تا 18:30 بعد از ظهر
                  </p>
                </div>
                <div className="col-sm-6">
                  <h3>اطلاعات تماس </h3>
                  <p>جهت دربافت مشاوره رایگان قبل از خرید با ما تماس بگیرید.</p>
                  <address>تهران، میدان هفت تیر، مجمتع صدف پلاک 82</address>
                  <p>
                    شماره تماس:{" "}
                    <a href="#" className="text-reset">
                      <u>469-537-2410</u>
                    </a>
                  </p>
                  <p>
                    خبر الکترونیکی:{" "}
                    <a href="#" className="text-reset">
                      <u>contact@example.com</u>
                    </a>
                  </p>
                  <p>
                    ساعات کار: شنبه تا چهارشنبه
                    <br />
                    9:30 صبح تا 18:30 بعد از ظهر
                  </p>
                </div>
              </div>

              <hr className="my-5" />

              <div className="row">
                <div className="col-12">
                  <h2>تماس با ما</h2>
                  <p>
                    لطفا فرم زیر را پر کنید و به زودی با شما تماس خواهیم گرفت.
                    آدرس ایمیل شما منتشر نخواهد شد.
                  </p>

                  <form className="contact-form" onSubmit={handleSubmitForm}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <Input
                            value={fullname}
                            setValue={setFullname}
                            type="text"
                            placeholder="نام و نام خانوادگی"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <Input
                            value={phoneNumber}
                            setValue={setPhoneNumber}
                            type="text"
                            placeholder="شماره موبایل"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <Input
                            value={email}
                            setValue={setEmail}
                            type="email"
                            placeholder="ایمیل"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <Input
                            value={subject}
                            setValue={setSubject}
                            type="text"
                            placeholder="عنوان"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="mb-3">
                          <textarea
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            id="con-message"
                            name="message"
                            cols={40}
                            rows={6}
                            style={{ resize: "none" }}
                            className="form-control"
                            placeholder="متن درخواست"
                          ></textarea>
                        </div>
                      </div>

                      <div className="col-md-12 text-start">
                        <Button
                          text="ارسال پیام"
                          disabled={!isButtonActive()}
                          btnClass="btn-primary w-100"
                          isLoading={loading}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default ContactUs;
