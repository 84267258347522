import { Dispatch, SetStateAction } from "react";
import Toast from "../Utils/Toast";
import { useNavigate } from "react-router";

interface useAuthReturns {
  handleLogout: () => void;
}

export const useAuth = (
  setToken: Dispatch<SetStateAction<string | null>>
): useAuthReturns => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    setToken(null);
    navigate("/");
    Toast("success", "با موفقیت خارج شدید!");
  };

  return { handleLogout };
};
