import React from "react";

interface PageHeaderProps {
  title: string;
}

const PageHeader = (props: PageHeaderProps) => {
  return (
    <section className="pt-4 pb-4">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              className="border d-flex justify-content-center align-items-center bg-dark-overlay-9 position-relative text-center rounded-3 overflow-hidden"
              style={{ height: 110 }}
            >
              <img
                src="/imgs/baner.webp"
                className="position-absolute w-100 opacity-50 "
              />
              <h1 className="z-10 text-white">{props.title}</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageHeader;
